interface CalcChildProps {
  direction: 'left' | 'right' | undefined;
  length: number;
  fullScroll: boolean | undefined;
  currentChild: number;
}

export const calcChildFromProps = (props: CalcChildProps) => {
  const { direction, length, fullScroll, currentChild } = props;
  let resultChild = 0;
  if (direction === 'left') {
    if (fullScroll) {
      resultChild = 0;
    } else if (currentChild === 0) {
      resultChild = length - 1;
    } else {
      resultChild = currentChild - 1;
    }
  }
  if (direction === 'right') {
    if (fullScroll) {
      resultChild = length - 1;
    } else if (currentChild === length - 1) {
      resultChild = 0;
    } else {
      resultChild = currentChild + 1;
    }
  }
  return resultChild;
};
