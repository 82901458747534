export interface Options {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export class Registry {
  public registry: WeakMap<HTMLElement | Window, Options>;

  constructor() {
    this.registry = new WeakMap();
  }

  public getElement(elem: HTMLElement | Window) {
    return this.registry.get(elem);
  }

  /**
   * administrator for lookup in the future
   *
   * @method add
   * @param {HTMLElement | Window} element - the item to add to root element registry
   * @param {Option} options
   * @param {Option.root} root - contains optional root e.g. window, container div, etc
   * @param {Option.watcher} observer - optional
   * @public
   */
  public addElement(element: HTMLElement | Window | null, options?: Options): void {
    if (!element) {
      return;
    }

    this.registry.set(element, options || {});
  }

  /**
   * reset weak map
   *
   * @method destroy
   * @public
   */
  public destroyRegistry(): void {
    this.registry = new WeakMap();
  }
}
