/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-lines */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'utils/classnames';
import { useIsomorphicLayoutEffect, useWindowSize } from 'hooks';
import { scrollToPosition } from 'utils/scroll-to-position';
import { FromTablet } from 'utils/tablet';
import { overscroll, calcChildFromProps, onCarouselScroll, renderExtra } from 'utils/carousel-utils';
import { CarouselProps, OnControlClickProps } from './carousel.types';
import { wrapperStyles, Container, SafariScrollGap } from './carousel.styles';
import { CarouselControl } from '../carousel-control';

const INDENTATION = 50;
export const DATA_QA_CAROUSEL_TEST_RIGHT = 'carousel-test-right';
export const DATA_QA_CAROUSEL_TEST_LEFT = 'carousel-test-left';
export const DATA_QA_ROOT_CAROUSEL_CONTAINER = 'carousel-root-container';
export const DATA_QA_CAROUSEL_CONTAINER = 'carousel-container';

export const DATA_QA_LEFT_ARROW = 'left-arrow';
export const DATA_QA_RIGHT_ARROW = 'right-arrow';

const windowSize = {
  mobileMin: 320,
  mobileMax: 1023,
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  className,
  fullScroll,
  testMock,
  controlSettings,
  autoplay,
  onChange,
  hideControls,
  isBannerCarousel,
  stepQuantity = 1,
  ...props
}) => {
  const { t } = useTranslation();
  const autoplayTime = autoplay ? Number(autoplay) : 0;
  const containerRef = useRef<HTMLDivElement>(null);
  const [inScrollNow, setInScrollNow] = useState(false);
  const [isMobileSafari, setIsMobileSafari] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const { width } = useWindowSize();
  const [currentChild, setCurrentChild] = useState(isBannerCarousel && children ? children.length : 0);
  const [gap, setGap] = useState(20);
  const [currentTimerDate, setCurrentTimerDate] = useState(Date.now());
  const [currentTimer, setCurrentTimer] = useState<NodeJS.Timeout | null>(null);
  const [scrollTimer, setScrollTimer] = useState<NodeJS.Timeout | null>(null);

  const onControlClick = useCallback(
    (clickProps: OnControlClickProps = {}) => {
      let { cancelAnimations, customGap } = clickProps;
      const element = containerRef.current;
      const carouselGap = customGap || gap;
      if ((clickProps.direction && inScrollNow) || !element) return;
      if (element) {
        const { offset, stop } = overscroll({
          element,
          isBannerCarousel,
          currentChild,
          setCurrentChild,
          gap: carouselGap,
          onChange,
          fullScroll,
          setInScrollNow,
          direction: clickProps.direction,
          stepQuantity,
        });
        if (stop) return;
        if (onChange) onChange(offset, clickProps.direction);
        const scrollSpeed = cancelAnimations ? { maxDuration: 0, minDuration: 0 } : { maxDuration: 300 };
        scrollToPosition(
          { x: offset, y: 0 },
          { ...scrollSpeed, elementToScroll: element, easingFn: 'easeInOutQuint' },
        ).then(() => {
          setInScrollNow(false);
          setCurrentChild(
            calcChildFromProps({
              direction: clickProps.direction,
              currentChild,
              length: element.children.length,
              fullScroll,
            }),
          );
        });
      }
    },
    [stepQuantity, currentChild, gap, inScrollNow, onChange, isBannerCarousel, fullScroll],
  );
  const checkCanScroll = useCallback(() => {
    const element = containerRef.current;
    if (element) {
      const { scrollLeft, offsetWidth, scrollWidth } = element;
      setCanScrollLeft(scrollLeft > INDENTATION);
      setCanScrollRight(!(scrollWidth - offsetWidth - INDENTATION < scrollLeft));
    }
  }, []);
  useIsomorphicLayoutEffect(() => {
    if (!isBannerCarousel) {
      checkCanScroll();
    } else {
      setCanScrollLeft(true);
      setCanScrollRight(true);
    }
  }, [checkCanScroll]);
  useIsomorphicLayoutEffect(() => {
    const customGap = width >= windowSize.mobileMin && width <= windowSize.mobileMax ? 12 : 20;
    setGap(customGap);
    setIsMobileSafari(
      customGap === 12 &&
        navigator.userAgent.indexOf('Chrome') === -1 &&
        navigator.userAgent.indexOf('Safari') > -1,
    );
    onControlClick({ cancelAnimations: true, customGap });
  }, [width]);
  useEffect(() => {
    if (!autoplayTime) return () => {};
    if (width >= windowSize.mobileMin && width <= windowSize.mobileMax) return () => {};
    const timer = setTimeout(() => {
      onControlClick({ direction: 'right' });
    }, autoplayTime);
    setCurrentTimerDate(Date.now());
    setCurrentTimer(timer);
    return () => clearTimeout(timer);
  }, [width, currentChild, onControlClick, autoplayTime]);

  const handleMouseEnter = () => {
    if (!autoplayTime || inScrollNow) return;
    if (currentTimer) clearTimeout(currentTimer);
  };
  const handleMouseLeave = () => {
    if (!autoplayTime || inScrollNow) return;
    if (Date.now() - currentTimerDate >= autoplayTime) {
      onControlClick({ direction: 'right' });
    } else {
      const newTimer = setTimeout(
        () => {
          onControlClick({ direction: 'right' });
        },
        autoplayTime - (Date.now() - currentTimerDate),
      );
      if (currentTimer) clearTimeout(currentTimer);
      setCurrentTimer(newTimer);
    }
  };

  const scrollProps = {
    isBanner: isBannerCarousel,
    currentElement: currentChild,
    setCurrentElement: setCurrentChild,
    onChangeFn: onChange,
    elementGap: gap,
    timer: scrollTimer,
    setTimer: setScrollTimer,
    scrollState: inScrollNow,
    setScrollState: setInScrollNow,
    checkScroll: checkCanScroll,
  };
  return (
    <div
      className={cn(className, wrapperStyles)}
      {...props}
      data-qa={DATA_QA_ROOT_CAROUSEL_CONTAINER}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {testMock ? (
        <>
          <span
            onClick={() => onControlClick({ direction: 'right' })}
            onKeyDown={() => false}
            data-qa={DATA_QA_CAROUSEL_TEST_LEFT}
            role="presentation"
          >
            test
            {children}
          </span>
          <span
            onClick={() => onControlClick({ direction: 'left' })}
            onKeyDown={() => false}
            data-qa={DATA_QA_CAROUSEL_TEST_RIGHT}
            role="presentation"
          >
            test
            {children}
          </span>
        </>
      ) : (
        <>
          <FromTablet>
            {canScrollLeft && controlSettings && !hideControls && (
              <CarouselControl
                {...controlSettings}
                label={t('ui.prevSlide')}
                direction="left"
                onClick={() => onControlClick({ direction: 'left' })}
                data-qa={DATA_QA_LEFT_ARROW}
              />
            )}
          </FromTablet>
          <Container
            style={{ '--grid-gap': `${gap}px` }}
            ref={containerRef}
            onScroll={onCarouselScroll(scrollProps)}
            inScrollNow={inScrollNow}
            className={cn({ extendMobilePadding: isBannerCarousel })}
            data-qa={DATA_QA_CAROUSEL_CONTAINER}
          >
            {isBannerCarousel && children && renderExtra(children, 'prev')}
            {children}
            {isBannerCarousel && children && renderExtra(children, 'next')}
            {!isBannerCarousel && isMobileSafari && <SafariScrollGap />}
          </Container>
          <FromTablet>
            {canScrollRight && controlSettings && !hideControls && (
              <CarouselControl
                {...controlSettings}
                label={t('ui.nextSlide')}
                direction="right"
                onClick={() => onControlClick({ direction: 'right' })}
                data-qa={DATA_QA_RIGHT_ARROW}
              />
            )}
          </FromTablet>
        </>
      )}
    </div>
  );
};
