import { api, ApiRequestConfig } from 'api';
import { FetchModelProps, ModelJsonContent } from 'features/page-model/types';

export type GetModelParams = {
  params: FetchModelProps;
  config?: ApiRequestConfig;
};

export const getModel = async ({ params, config }: GetModelParams) =>
  api.get<ModelJsonContent, FetchModelProps>('getModel', params, config);
