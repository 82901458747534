import { Experiment } from 'features/page-model/types';
import { createSelector } from 'reselect';
import { RootState } from 'store';

export const getExperiments = createSelector(
  ({ app }: RootState) => app.pageModel.data?.experiments,
  (experiments) => experiments || [],
);

export const getUUID = createSelector(
  ({ uuid }: RootState) => uuid,
  (uuid) => uuid,
);

export const HIDE_GGR_INTERNAL_ID = 'hide_ggr';

export const getIsGGRDisabled = createSelector(
  getExperiments,
  (experiments) =>
    experiments?.some((experiment: Experiment) => experiment.internalId === HIDE_GGR_INTERNAL_ID) ?? false,
);
