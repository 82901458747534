import { KV } from 'types/utils';

export const parseCookie = (str = ''): KV =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
      return acc;
    }, {});
