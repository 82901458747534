import { Registry } from './registry';

export enum CallbackType {
  enter = 'enter',
  exit = 'exit',
}

export class Notifications {
  public registry: Registry;

  constructor() {
    this.registry = new Registry();
  }

  /**
   * Adds an EventListener as a callback for an event key.
   * @param type 'enter' or 'exit'
   * @param element
   * @param callback The callback function to invoke when the event occurs
   */
  public addCallback(type: CallbackType, element: HTMLElement | Window, callback: () => void): void {
    let entry;
    if (type === CallbackType.enter) {
      entry = { [CallbackType.enter]: callback };
    } else {
      entry = { [CallbackType.exit]: callback };
    }

    this.registry.addElement(element, { ...this.registry.getElement(element), ...entry });
  }

  /**
   * @hidden
   * Executes registered callbacks for key.
   * @param type
   * @param element
   * @param data
   */
  public dispatchCallback(
    type: CallbackType,
    element: HTMLElement | Window,
    data?: IntersectionObserverEntry,
  ): void {
    const getEl = this.registry.getElement(element);

    if (getEl) {
      const checkKey = (value: CallbackType) =>
        type === CallbackType[value] && Object.prototype.hasOwnProperty.call(getEl, value);

      if (checkKey(CallbackType.enter)) {
        getEl.enter(data);
      } else if (checkKey(CallbackType.exit)) {
        getEl.exit(data);
      }
    }
  }
}
