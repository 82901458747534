import { createSelector } from 'reselect';
import { createFeatureSelector } from 'create-feature-selector';
import { RootState, Dispatch } from 'store';
import { getHostname, getRegion } from 'utils/region';
import { getQaCookies } from 'utils/get-qa-cookies';
import { queryString } from 'utils/query-string';
import { getLangCookie } from 'utils/get-lang-cookie';
import { FetchModelProps } from 'features/page-model/types';
import { MAIN_PAGE_PATH_NAME, SLASH_PATH_NAME } from 'common/constants';
import { fetchModelThunk } from './page-model-slice';
import { GetModelParams } from './api';

export const SHOCK_PRODUCTS_EXPERIMENT = 'shockProducts';
export const SHOCK_PRODUCTS_MOBILE_VIEW = 'mobileView';

export const requiredFieldsSet = [
  'homeContent',
  'seoProperties',
  'header',
  'analytics',
  'experiments',
  'properties',
  'shockProducts',
];

export const rootSelector = createFeatureSelector('pageModel');
export const getModelJson = createSelector(rootSelector, (s) => s.data);
export const getModelError = createSelector(rootSelector, (s) => s.isError);

export const getSeoDescription = createSelector(getModelJson, (m) => m?.seoProperties.seotext);
export const getAnalytics = createSelector(getModelJson, (m) => m?.analytics || {});
export const getSeoProperties = createSelector(getModelJson, (m) => m?.seoProperties);
export const getRegionId = createSelector(getModelJson, (m) => m?.properties.regionId);
export const getPathname = createSelector(
  ({ router }: RootState) => router.path,
  (s) => s,
);
export const getSearch = createSelector(
  ({ router }: RootState) => router?.search,
  (search) => search,
);

export const getQuery = createSelector(getSearch, (search) => queryString.parse(search));
export const getHost = createSelector(
  ({ host }: RootState) => host,
  (s) => s,
);

export const getPageUrl = createSelector(getHost, getPathname, (host, pathName) => {
  const protocol = host?.includes('localhost') ? 'http://' : 'https://';
  return protocol + host + pathName;
});

export const getExperiments = createSelector(getModelJson, (m) => m?.experiments || []);

export const isShockProductsExperiment = createSelector(getExperiments, (experiments) =>
  experiments.some(({ internalId }) => internalId === SHOCK_PRODUCTS_EXPERIMENT),
);

export const getShockProducts = createSelector(getModelJson, (m) => m?.shockProducts);

export const getShockProductsMobileView = createSelector(
  isShockProductsExperiment,
  getShockProducts,
  getQuery,
  (isExperimentOn, products, query) =>
    isExperimentOn && Boolean(products) && query[SHOCK_PRODUCTS_MOBILE_VIEW] !== undefined,
);

export const fetchModel = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const hostname = getHostname(state.host || '');
  const apiKey = state.env.API_KEY;
  const qaCookie = getQaCookies(state.cookie);
  const lang = getLangCookie(state.cookie);
  const region = getRegion(hostname);
  const regex = RegExp(`${MAIN_PAGE_PATH_NAME}/?`);
  const pathname = state.router?.path.replace(regex, SLASH_PATH_NAME);
  const requiredFields = requiredFieldsSet.join(',');
  const params: FetchModelProps = {
    pathname,
    region,
    requiredFields,
    lang,
  };
  const options: GetModelParams = {
    params,
  };

  if (qaCookie) {
    options.config = { ...options.config, headers: { ...options.config?.headers, cookie: qaCookie } };
  }

  if (apiKey) {
    options.config = { ...options.config, headers: { ...options.config?.headers, 'x-api-key': apiKey } };
  }

  return dispatch(fetchModelThunk(options));
};
