import React, { FC } from 'react';
import { LayoutContainer } from 'components/container-components/layout-container';
import { useSelector } from 'react-redux';
import { TopBanner, LegalText, OnlyInStore, ShockProducts } from './components';
import {
  getShockProducts,
  isShockProductsExperiment,
  getShockProductsMobileView,
  SHOCK_PRODUCTS_MOBILE_VIEW,
} from '../page-model/ducks/get-model';
import { layout, onlyInStore, onlyInStoreText } from './styles';

export const DATA_QA_BOTTOM_ONLY_IN_STORE = 'shock-price-bottom-only-in-store';

export const ShockPriceContainer: FC = () => {
  const shockProducts = useSelector(getShockProducts);
  const isShockProductsMobileView = useSelector(getShockProductsMobileView);
  let { legalText = '', legalUrl = '', legalUrlLabel = '', items = [] } = shockProducts || {};
  const isShockProducts = useSelector(isShockProductsExperiment);
  const limitedItems = items.slice(0, 5).filter(({ visibility }) => visibility);

  if (!isShockProducts || !shockProducts || !limitedItems.length) {
    return null;
  }

  if (isShockProductsMobileView) legalUrl += `?${SHOCK_PRODUCTS_MOBILE_VIEW}`;

  return (
    <>
      <TopBanner />
      <LayoutContainer className={layout}>
        <ShockProducts items={limitedItems} />
        <LegalText text={legalText} url={legalUrl} urlLabel={legalUrlLabel} />
        <OnlyInStore
          textClassName={onlyInStoreText}
          className={onlyInStore}
          dataQAId={DATA_QA_BOTTOM_ONLY_IN_STORE}
        />
      </LayoutContainer>
    </>
  );
};
