import { splitCookies } from './split-cookies';
import { parseUserData } from './parse-user-data';

const STORAGE_KEY = 'enabledLogAnalytics';

type GAEvent = {
  event: string;
  params: unknown;
};

/**
 * Send events to analytics
 * @class
 * @public
 */

class AnalyticsUtils {
  hasFlagLogAnalytics: string | null = null;

  hasGTMLoaded = false;

  private eventsQueue: GAEvent[] = [];

  constructor() {
    if (!__IS_SERVER__) {
      this.hasFlagLogAnalytics = localStorage.getItem(STORAGE_KEY);
      if (!this.hasFlagLogAnalytics) {
        localStorage.setItem(STORAGE_KEY, '');
      }

      window.dataLayer = window.dataLayer ?? [];
      this.hasGTMLoaded = !!window.dataLayer.find((eventObj) => eventObj.event === 'gtm.load');

      if (!this.hasGTMLoaded) {
        const resolveEvents = () => {
          this.hasGTMLoaded = true;
          this.eventsQueue.forEach(({ event, params }) => this.sendEvent(event, params));
          this.pageViewEvent();
        };

        const originalPush = window.dataLayer.push;
        window.dataLayer.push = function push(...args) {
          if (args[0].event === 'gtm.load') resolveEvents();
          return originalPush.apply(this, args);
        };
      }
    }
  }

  public pageViewEvent() {
    const cookieData = splitCookies(document.cookie);
    const userData = parseUserData(cookieData.userData);
    this.sendEvent('GA:pageview', {
      pageType: 'Main', // тип страницы
      pagePath: '/', // полный url страницы, не должен пересекаться с url других страниц
      userId: userData.customerNumber ?? null, // customerId из сириуса или null
      userAuth: cookieData['user-auth'] ? 1 : 0, // или 0
      clientType: userData.loyaltyCategory ?? null, // тип пользователя: Профи, Вендор, Авторизованный, Держатель СК, Неавторизованный
      loyaltyCardNumber: userData.loyaltyCardNumber ?? null, // номер карты сервисной карты (если нет, не передается),
    });
  }

  /**
   * Send event with params
   * @param {string} event name of event
   * @param {*} params any data
   * @public
   */

  public sendEvent(event: string, params: unknown) {
    if (!!this.hasFlagLogAnalytics && this.hasGTMLoaded) {
      // eslint-disable-next-line no-console
      console.log(event, params);
    }

    if (this.hasGTMLoaded) {
      document.dispatchEvent(new CustomEvent(event, { detail: params }));
    } else {
      this.eventsQueue.push({ event, params });
    }
  }
}

export const analyticsUtils = new AnalyticsUtils();
