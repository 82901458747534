import { api, ApiRequestConfig } from 'api';
import { CatalogueJson } from 'components/seo-components/seo-carousel';
import { FetchCatalogueProps } from '../types';

export type GetCatalogueParams = {
  params: FetchCatalogueProps;
  config?: ApiRequestConfig;
};

export const getCatalogue = async ({ params, config }: GetCatalogueParams) =>
  api.get<CatalogueJson, FetchCatalogueProps>('get-flex-catalogue', params, config);
