import { Notifications, CallbackType } from './notification';
import { Registry } from './registry';
import { EntryForKey, IntersectionObserverOptions, PotentialRootEntry } from './types';
import { stringifyOptions } from './stringify-options';
import { determineMatchingElements } from './determine-matching-elements';

class IntersectionObserverController extends Notifications {
  private readonly elementRegistry: Registry;

  constructor() {
    super();
    this.elementRegistry = new Registry();
  }

  /**
   * Adds element to observe via IntersectionObserver and stores element + relevant callbacks and observer options in static
   * administrator for lookup in the future
   * @param {HTMLElement | Window} element
   * @param {Object} options
   * @public
   */
  public observe(element: HTMLElement | null, options: IntersectionObserverOptions = {}): void {
    if (!element) {
      return;
    }
    this.elementRegistry.addElement(element, options);
    this.setupObserver(element, options);
  }

  /**
   * Unobserve target element and remove element from static admin
   * @method unobserve
   * @param element
   * @param {Object} options
   * @public
   */
  public unobserve(element: HTMLElement | null, options: IntersectionObserverOptions = {}): void {
    const matchingRootEntry: EntryForKey | null = this.findMatchingRootEntry(options);
    if (element && matchingRootEntry) {
      const { intersectionObserver } = matchingRootEntry;
      intersectionObserver.unobserve(element);
    }
  }

  /**
   * register event to handle when intersection observer detects enter
   * @method addEnterCallback
   * @public
   */
  public addEnterCallback(element: HTMLElement | Window, callback: (data?: void) => void) {
    this.addCallback(CallbackType.enter, element, callback);
  }

  /**
   * register event to handle when intersection observer detects exit
   * @method addExitCallback
   * @public
   */
  public addExitCallback(element: HTMLElement | Window, callback: (data?: void) => void) {
    this.addCallback(CallbackType.exit, element, callback);
  }

  private dispatchEnterCallback(element: HTMLElement | Window, entry: IntersectionObserverEntry) {
    this.dispatchCallback(CallbackType.enter, element, entry);
  }

  private dispatchExitCallback(element: HTMLElement | Window, entry: IntersectionObserverEntry) {
    this.dispatchCallback(CallbackType.exit, element, entry);
  }

  /**
   * cleanup data structures and unobserve elements
   * @method destroy
   * @public
   */
  public destroy(): void {
    this.elementRegistry.destroyRegistry();
  }

  /**
   * use function composition to curry options
   * @method setupOnIntersection
   * @param {Object} options
   */
  private setupOnIntersection(
    options: IntersectionObserverOptions,
  ): (ioEntries: IntersectionObserverEntry[]) => void {
    return (ioEntries) => this.onIntersection(options, ioEntries);
  }

  private setupObserver(element: HTMLElement, options: IntersectionObserverOptions): void {
    if (!__IS_SERVER__) {
      const { root = window } = options;
      if (root) {
        const potentialRootMatch: PotentialRootEntry | null | undefined = this.findRoot(root);
        let matchingEntryForRoot;
        if (potentialRootMatch) {
          matchingEntryForRoot = determineMatchingElements(options, potentialRootMatch);
        }
        if (matchingEntryForRoot) {
          const { elements, intersectionObserver } = matchingEntryForRoot;
          elements.push(element);
          if (intersectionObserver) {
            intersectionObserver.observe(element);
          }
        } else {
          const intersectionObserver = this.newObserver(element, options);
          const observerEntry: EntryForKey = {
            elements: [element],
            intersectionObserver,
            options,
          };
          // and add entry to WeakMap under a root element
          // with watcher so we can use it later on
          const stringifiedOptions = stringifyOptions(options);

          if (potentialRootMatch) {
            // if share same root and need to add new entry to root match
            // not functional but :shrug
            potentialRootMatch[stringifiedOptions] = observerEntry;
          } else {
            // no root exists, so add to WeakMap
            this.elementRegistry.addElement(root, {
              [stringifiedOptions]: observerEntry,
            });
          }
        }
      }
    }
  }

  private newObserver(element: HTMLElement, options: IntersectionObserverOptions): IntersectionObserver {
    // No matching entry for root in static admin, thus create new IntersectionObserver instance
    const { root, rootMargin, threshold } = options;
    const newIO = new IntersectionObserver(this.setupOnIntersection(options).bind(this), {
      root,
      rootMargin,
      threshold,
    });
    newIO.observe(element);
    return newIO;
  }

  private onIntersection(
    options: IntersectionObserverOptions,
    ioEntries: IntersectionObserverEntry[],
  ): void {
    ioEntries.forEach((entry) => {
      const { isIntersecting, intersectionRatio } = entry;
      let threshold = options.threshold || 0;
      if (Array.isArray(threshold)) {
        threshold = threshold[threshold.length - 1];
      }
      // then find entry's callback in static administration
      const matchingRootEntry: EntryForKey | null = this.findMatchingRootEntry(options);
      // first determine if entry intersecting
      if (isIntersecting || intersectionRatio > threshold) {
        if (matchingRootEntry) {
          matchingRootEntry.elements.some((element: HTMLElement) => {
            if (element && element === entry.target) {
              this.dispatchEnterCallback(element, entry);
              return true;
            }
            return false;
          });
        }
      } else if (matchingRootEntry) {
        matchingRootEntry.elements.some((element: HTMLElement) => {
          if (element && element === entry.target) {
            this.dispatchExitCallback(element, entry);
            return true;
          }
          return false;
        });
      }
    });
  }

  private findRoot(root: HTMLElement | Window): IntersectionObserverOptions | null | undefined {
    if (this.elementRegistry) {
      return this.elementRegistry.getElement(root);
    }
    return null;
  }

  private findMatchingRootEntry(options: IntersectionObserverOptions): EntryForKey | null {
    const { root = window } = options;
    if (root) {
      const matchingRoot: PotentialRootEntry | null | undefined = this.findRoot(root);
      if (matchingRoot) {
        const signifiedOptions = stringifyOptions(options);
        return matchingRoot[signifiedOptions];
      }
    }
    return null;
  }
}

export const intersectionObserverController = new IntersectionObserverController();
