import * as React from 'react';
import { Store } from 'redux';
import { RootPage } from './root-page';

export type Route = {
  key: React.Key;
  component?: React.ElementType;
  path: string | string[];
  exact?: boolean;
  strict?: boolean;
  loadData?: (store: Store, url: string) => Promise<unknown>;
  loadModel?: (store: Store, url: string) => Promise<unknown>;
  routes?: Route[];
};

export type RootRoute = Route & {
  routes: Route[];
};

export const routes: RootRoute[] = [
  {
    key: 'root',
    component: RootPage,
    path: ['/main-page', '/'],
    routes: [],
    strict: true,
  },
];
