import { styled } from 'linaria/react';
import { mediaSize } from '../../../styles/media-size';

export const SeoCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 0;
`;

export const DepartmentCard = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 247px;
  padding: 0;
  margin: 0;
  font-style: normal;
  scroll-snap-align: start;
  line-height: 0;
  text-align: left;

  ${mediaSize.forDesktopOnly} {
    width: 189px;
    margin-right: 4px;
  }
  ${mediaSize.forTabletOnly} {
    width: 174px;
  }
  ${mediaSize.forMobileOnly} {
    width: 187px;
  }
`;

export const HeaderLink = styled.a`
  padding: 0;
  margin-bottom: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  color: #333333;
  border: none;
  outline: none;
  cursor: pointer;
`;
