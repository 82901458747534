import { defaultScroll, instaScroll } from '../scroll-to-position';

export type OverscrollProps = {
  element: HTMLDivElement;
  isBannerCarousel: boolean | undefined;
  currentChild: number;
  setCurrentChild: (newChild: number) => void;
  gap: number;
  onChange: ((scroll: number) => void) | undefined;
  setInScrollNow: (isScroll: boolean) => void;
  direction: 'left' | 'right' | undefined;
  fullScroll: boolean | undefined;
  stepQuantity?: number;
};
export const overscroll = ({
  element,
  isBannerCarousel,
  currentChild,
  setCurrentChild,
  gap,
  onChange,
  setInScrollNow,
  direction,
  fullScroll,
  stepQuantity = 1,
}: OverscrollProps) => {
  const { scrollLeft } = element;
  let calcChild = element.children[currentChild];
  if (fullScroll) {
    calcChild = element.children[element.children.length - 1];
  }
  if (direction === 'left') {
    calcChild = element.children[currentChild - 1];
    if (fullScroll) {
      calcChild = element.children[0];
    }
    if (currentChild === 0) {
      calcChild = element.children[element.children.length - 1];
    }
  }
  const scrolledElem = calcChild && calcChild.clientWidth;
  const step = scrolledElem + gap;
  setInScrollNow(true);
  let offset = step * currentChild;
  if (isBannerCarousel) {
    if (direction === 'left') {
      offset = scrollLeft - step;
      if (currentChild < element.children.length / 3) {
        offset = step * (element.children.length / 3 + currentChild);
        instaScroll(offset, element).then(() => {
          const offsetNew = step * (element.children.length / 3 + currentChild - 1);
          if (onChange) onChange(offsetNew);
          defaultScroll(offsetNew, element).then(() => {
            setInScrollNow(false);
            setCurrentChild(element.children.length / 3 + currentChild - 1);
          });
        });
        return { offset: 0, stop: true };
      }
    }
    if (direction === 'right') {
      offset = scrollLeft + step;
      if (currentChild >= (2 * element.children.length) / 3) {
        offset = step * (currentChild - element.children.length / 3);
        instaScroll(offset, element).then(() => {
          const offsetNew = step * (currentChild - element.children.length / 3 + 1);
          defaultScroll(offsetNew, element).then(() => {
            if (onChange) onChange(offsetNew);
            setInScrollNow(false);
            setCurrentChild(currentChild - element.children.length / 3 + 1);
          });
        });
        return { offset: 0, stop: true };
      }
    }
    if (currentChild < element.children.length / 3) {
      offset = step * (element.children.length / 3 + currentChild);
      instaScroll(offset, element).then(() => {
        const offsetNew = step * (element.children.length / 3 + currentChild + 1);
        defaultScroll(offsetNew, element).then(() => {
          if (onChange) onChange(offsetNew);
          setInScrollNow(false);
          setCurrentChild(element.children.length / 3 + currentChild + 1);
        });
      });
      return { offset: 0, stop: true };
    }
    if (currentChild >= (2 * element.children.length) / 3) {
      offset = step * (currentChild - element.children.length / 3);
      instaScroll(offset, element).then(() => {
        setCurrentChild(currentChild - element.children.length / 3);
        setInScrollNow(false);
        if (onChange) onChange(offset);
      });
      return { offset: 0, stop: true };
    }
  }
  if (direction === 'left') {
    offset = scrollLeft - step * stepQuantity;
    if (fullScroll) {
      offset = 0;
    } else if (currentChild === 0) {
      offset = step * (element.children.length - 1);
    }
  }
  if (direction === 'right') {
    offset = scrollLeft + step * stepQuantity;
    if (fullScroll) {
      offset = step * (element.children.length - 1);
    } else if (currentChild === element.children.length - 1) {
      offset = 0;
    }
  }
  return { offset, stop: false };
};
