import { styled } from 'linaria/react';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: var(--space-200);
  overflow-x: hidden;
  overflow-y: hidden;

  @media (--from-desktop) {
    margin-top: var(--space-525);
    overflow-x: visible;
    overflow-y: initial;
  }
`;
export const TopLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`;

export const OfferCarouselContainer = styled.div`
  margin-bottom: var(--space-300);
`;

export const FooterLayoutContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
