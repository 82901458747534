import { css } from 'linaria';

export const image = css`
  @media (--to-tablet) {
    width: 234px;
    height: 152px;
  }
  @media (--only-tablet) {
    width: 379px;
    height: 254px;
  }
  @media (--from-desktop) {
    width: 425px;
    height: 285px;
  }
`;
