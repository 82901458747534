import { css } from 'linaria';
import { styled } from 'linaria/react';

export const HeaderContainer = styled.div`
  width: 100%;
  @media (--to-tablet) {
    [data-qa-header='sticky'] {
      margin-bottom: 0;
    }
    header {
      padding-bottom: 0;
    }
  }

  @media (--only-mobile) {
    margin-bottom: var(--space-100);
  }
`;

export const remoteCarousel = css`
  @media (--from-tablet) {
    width: var(--layout-width);
  }
  @media (--to-tablet) {
    margin-right: var(--space-200);
    margin-left: var(--space-200);
  }

  div {
    font-family:
      Roboto,
      apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Oxygen,
      Ubuntu,
      Cantarell,
      Open Sans,
      Helvetica Neue,
      sans-serif !important;
  }

  [data-qa='products-carousel-seemore-link'] {
    font-size: 16px;

    span [role='presentation'] {
      width: 9px !important;
      height: 9px !important;
    }
  }
`;
