import { styled } from 'linaria/react';
import { mediaSize } from 'styles/media-size';

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100vw;
  max-width: 1060px;
  margin: 0 auto 80px auto;
  ${mediaSize.forDesktopOnly} {
    width: 100%;
    max-width: 860px;
  }
  ${mediaSize.forTabletOnly} {
    width: calc(var(--layout-width));
    padding: 0 var(--layout-gap);
  }
  ${mediaSize.forMobileOnly} {
    max-width: calc(100% + var(--layout-gap));
    padding: 0 0 0 var(--layout-gap);
  }
`;
