import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const wrapperStylesAdaptive = css`
  ${mediaSize.forTabletOnly} {
    max-width: calc(var(--layout-width) + 2 * var(--layout-gap));
    overflow: hidden;
  }
  ${mediaSize.forMobileOnly} {
    max-width: calc(100% + var(--layout-gap));
    overflow: hidden;
  }
`;
export const itemStyles = css`
  border: none;
  cursor: pointer;
  --translate-distance: 0 !important;
`;
export const bannerItem = css`
  box-sizing: content-box;
  width: 73vw;
  scroll-snap-align: start;
  padding: 0 !important;
  border: none;
  --button-bg: none !important;
  --button-hover-bg: none !important;
  border-radius: 4px;
  --translate-distance: 0 !important;
  @media (--from-tablet) {
    scroll-snap-align: center;
    width: var(--layout-width);
  }
`;
