import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const grid = css`
  display: grid;
  grid-gap: 22px 20px;
  grid-template-columns: 280px 380px;
  grid-template-rows: 196px 140px;
  height: 100%;
  & picture {
    border-radius: 4px;
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  & > picture:nth-child(3) {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  ${mediaSize.forDesktopOnly} {
    grid-gap: 20px 18px;
    grid-template-columns: 220px 300px;
    grid-template-rows: 154px 110px;
  }
  ${mediaSize.forTabletOnly} {
    grid-gap: 12px 12px;
    grid-template-columns: minmax(266px, 1.33fr) minmax(380px, 2.33fr);
    grid-template-rows: 194px 142px;
    width: 100%;
  }
  ${mediaSize.forMobileOnly} {
    grid-gap: 12px 10px;
    grid-template-columns: minmax(112px, calc(100% - 65%)) minmax(164px, calc(100% - 35%));
    grid-template-rows: minmax(82px, calc(100% - 55%)) minmax(58px, calc(100% - 65%));
    width: 100%;
  }
`;
