export type ClassValue =
  | string
  | number
  | undefined
  | null
  | false
  | ClassValue[]
  | { [id: string]: boolean | undefined | null };

export function cn(...classNames: ClassValue[]): string {
  const result: (string | number)[] = [];

  for (const className of classNames) {
    if (!className) continue;

    switch (typeof className) {
      case 'string':
      case 'number':
        result.push(className as string);
        break;
      case 'object':
        if (Array.isArray(className)) {
          className.forEach((item) => result.push(cn(item)));
        } else {
          for (const key of Object.keys(className)) {
            if (className[key]) {
              result.push(key);
            }
          }
        }
        break;
    }
  }

  return result.join(' ');
}
