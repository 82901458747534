import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogueJson } from 'components/seo-components/seo-carousel';
import { getCatalogue } from './api';

type InitialState = { isError: boolean; data: CatalogueJson | null };

const initialState: InitialState = { isError: false, data: null };

const fetchCatalogueThunk = createAsyncThunk('catalogue/FETCH_CATALOGUE', getCatalogue);

const { actions, reducer } = createSlice({
  name: 'catalogue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogueThunk.pending, (state) => {
        state.isError = false;
      })
      .addCase(fetchCatalogueThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.isError = false;
      })
      .addCase(fetchCatalogueThunk.rejected, (state) => {
        state.isError = true;
      });
  },
});

export { actions, reducer, fetchCatalogueThunk };
