import { css } from 'linaria';

export type OfferCardProps = {
  desktopRef: string;
  mobileRef: string;
  tabletRef: string;
  title: string;
};

export const bannerImage = css`
  width: 100%;
  height: auto;
  object-fit: scale-down;
  -webkit-user-drag: none;
`;
export const bannerPicture = css`
  display: block;
  width: 100%;
  height: auto;
  line-height: 0;
  & img {
    border: none;
    border-radius: 4px;
  }
`;
