import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const container = css`
  display: flex;
  justify-content: center;
  width: 100%;
  white-space: initial;
  ${mediaSize.forMobileOnly} {
    justify-content: flex-start;
  }
`;

export const offerCard = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 1180px;
  width: 100%;
  height: 100vh;
  max-height: 264px;
  & picture {
    display: block;
    height: 100%;
    ${mediaSize.forMobileOnly} {
      max-height: 166px;
    }
    & img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;

      ${mediaSize.forTabletOnly} {
        object-position: 25%;
      }
      ${mediaSize.forMobileOnly} {
        object-position: 15%;
        min-height: 166px;
        border-radius: 4px 4px 0 0;
      }
    }
  }
  ${mediaSize.forDesktopOnly} {
    min-width: 940px;
  }
  ${mediaSize.forTabletOnly} {
    min-width: 660px;
  }
  ${mediaSize.forMobileOnly} {
    flex-direction: column-reverse;
    align-items: baseline;
    min-width: 262px;
    height: 100%;
    max-height: none;
  }
`;
