import { css } from 'linaria';

export const legalText = css`
  padding: var(--space-500) 0;
  text-align: center;
  color: var(--text-secondary);

  a {
    color: var(--text-secondary);
  }
`;
