import React, { FC } from 'react';
import { analyticsUtils } from 'utils/analytics-utils';
import { patchUrlByLocale } from 'utils';
import { SubDepartment } from '../sub-department';
import { SeoCardProp } from './seo-card.types';
import { DepartmentCard, HeaderLink, SeoCardStyle } from './seo-card.styles';

export const DATA_QA_SEO_CARD_DEPARTMENT_CARD = 'seo-card-department-card';
export const DATA_QA_SEO_CARD_HEADER_LINK = 'seo-card-header-link';

export const SeoCard: FC<SeoCardProp> = (seoProps) => {
  const { name, sitePath, children } = seoProps;
  const handleHeaderClick = (divisionName: string) => () => {
    analyticsUtils.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'divisions-subdivisions', // блок, в котором расположен элемент
      elementName: 'division', // условное обозначение
      elementText: divisionName, // название отдела
    });
  };
  return (
    <SeoCardStyle>
      <HeaderLink
        href={patchUrlByLocale(sitePath)}
        onClick={handleHeaderClick(name)}
        data-qa={DATA_QA_SEO_CARD_HEADER_LINK}
      >
        {name}
      </HeaderLink>
      <DepartmentCard data-qa={DATA_QA_SEO_CARD_DEPARTMENT_CARD}>
        {children?.map((dep) => <SubDepartment key={dep.name} name={dep.name} sitePath={dep.sitePath} />)}
      </DepartmentCard>
    </SeoCardStyle>
  );
};
