import { Http, HttpRequestConfig, HttpInstance, HttpCancelToken } from '@okapi/http';
import { API_KEY, ORCHESTRATOR_HOST } from 'variables';
import log from 'loglevel';

export type ApiRequestConfig = HttpRequestConfig;
export type ApiCancelToken = HttpCancelToken;

export class Api {
  http: HttpInstance;

  constructor() {
    this.http = new Http({
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        'x-api-key': API_KEY,
      },
    });
  }

  public abortController = () => this.http.abortController();

  post = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data: Data,
    config?: ApiRequestConfig,
  ) =>
    this._doFetch<Response>(url, {
      method: 'POST',
      data,
      headers: config?.headers,
      ...config,
    });

  get = <Response = unknown, Data extends Record<string, string> = Record<string, string>>(
    url: string,
    data?: Data,
    config?: ApiRequestConfig,
  ) => {
    const params = new URLSearchParams(data).toString();
    const resultUrl = `${url}?${params}`;

    return this._doFetch<Response>(resultUrl, { method: 'GET', ...config });
  };

  private _doFetch<Response = unknown>(url: string, init: ApiRequestConfig) {
    const resultUrl = /^\/\//.test(url as string) ? `https:${url}` : `${ORCHESTRATOR_HOST}${url}`;
    return this.http
      .request<Response>(resultUrl, init)
      .then((response) =>
        response.status >= 200 && response.status <= 299 ? response.data : Promise.reject(response),
      )
      .catch((e) => {
        const {
          response: {
            data: { error, statusCode, message },
          },
        } = e;
        log.error('\nREQUEST ERROR:', error, statusCode, message, '\n');
        return Promise.reject(e.response);
      });
  }
}

export const api = new Api();
