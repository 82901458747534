import { styled } from 'linaria/react';

export const FadeContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: var(--layout-width);
  height: 78px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) 0.61%,
    rgba(255, 255, 255, 0.81321) 37.33%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
`;
