import React, { memo } from 'react';
import { Redirect } from '@okapi/router-react';
import { Remote } from '@okapi/remote';
import { convertProducts } from 'utils/convert-products';
import {
  LayoutContainer,
  FooterLayoutContainer,
  TopLayoutContainer,
  OfferCarouselContainer,
} from 'components/container-components/layout-container';
import { PromoCarousel } from 'components/promo-components/promo-carousel';
import { OfferCarousel } from 'components/offer-components/offer-carousel';
import { SeoCarousel } from 'components/seo-components/seo-carousel';
import { SeoText } from 'components/seo-components/seo-text';
import { GGR } from 'components/ggr-components/ggr';
import { useSelector } from 'react-redux';
import {
  getModelError,
  getModelJson,
  getPathname,
  getShockProductsMobileView,
} from 'features/page-model/ducks/get-model';
import { getCatalogue, getCatalogueError } from 'features/catalogue/ducks/get-catalogue';
import { MAIN_PAGE_PATH_NAME, SLASH_PATH_NAME } from 'common/constants';
import { getIsGGRDisabled } from 'selectors/experiments';
import { remoteCarousel, HeaderContainer } from './root-page.styles';
import { PageMeta } from './page-meta';
import { ShockPriceContainer } from '../../features/shock-price';

export const RootPage: React.FC = memo(function RootPage() {
  const modelData = useSelector(getModelJson);
  const catalogue = useSelector(getCatalogue);
  const modelError = useSelector(getModelError);
  const catalogueError = useSelector(getCatalogueError);
  const pathName = useSelector(getPathname);
  const isShockProductsMobileView = useSelector(getShockProductsMobileView);
  const hfPathName = pathName.includes(MAIN_PAGE_PATH_NAME) ? SLASH_PATH_NAME : '';
  const isGGRDisabled = useSelector(getIsGGRDisabled);

  if (modelError || catalogueError) {
    return <Redirect to="/500" />;
  }

  const turnkeyCardData = modelData?.homeContent?.ggr;

  const isGgrActive = turnkeyCardData && !isGGRDisabled;

  const seoTextData = modelData?.seoProperties?.seotext;

  const cardsData = modelData?.homeContent?.banners;

  const [topOfferData, middleOfferData, bottomOfferData] = modelData?.homeContent?.carousel || [];

  const [topProductsData, middleProductsData, bottomProductsData] = modelData?.homeContent?.offers || [];

  return (
    <>
      {!isShockProductsMobileView && (
        <>
          {modelData && <PageMeta modelJson={modelData} />}
          <HeaderContainer>
            <Remote
              widget="header-footer/header"
              id="main-header"
              data={{ withoutSearchbar: true, withoutCatalogButton: true, pathName: hfPathName }}
            />
          </HeaderContainer>
        </>
      )}
      <ShockPriceContainer />
      {!isShockProductsMobileView && (
        <>
          {topOfferData?.offers && (
            <TopLayoutContainer data-qa-offer-carousel="">
              <OfferCarouselContainer>
                <OfferCarousel data={topOfferData} />
              </OfferCarouselContainer>
            </TopLayoutContainer>
          )}
          {cardsData && (
            <TopLayoutContainer data-qa-promo-carousel="">
              <PromoCarousel content={cardsData} />
            </TopLayoutContainer>
          )}
          {topProductsData?.products && topProductsData.products.length && (
            <LayoutContainer data-qa-product-carousel="">
              <Remote
                widget="product-carousel/root"
                data={{
                  seeMoreLink: topProductsData.link,
                  title: topProductsData.title,
                  productsIds: convertProducts(topProductsData.products),
                  elementBlock: topProductsData.gaPosition,
                }}
                id={topProductsData.gaPosition}
                className={remoteCarousel}
              />
            </LayoutContainer>
          )}
          {middleOfferData && middleOfferData.offers && (
            <LayoutContainer data-qa-offer-carousel="">
              <OfferCarousel data={middleOfferData} />
            </LayoutContainer>
          )}
          {middleProductsData?.products && middleProductsData.products.length && (
            <LayoutContainer data-qa-product-carousel="">
              <Remote
                widget="product-carousel/root"
                data={{
                  title: middleProductsData.title,
                  productsIds: convertProducts(middleProductsData.products),
                  seeMoreLink: middleProductsData.link,
                  elementBlock: middleProductsData.gaPosition,
                }}
                id={middleProductsData.gaPosition}
                className={remoteCarousel}
              />
            </LayoutContainer>
          )}
          {bottomOfferData && middleOfferData.offers && (
            <LayoutContainer data-qa-offer-carousel="">
              <OfferCarousel data={bottomOfferData} />
            </LayoutContainer>
          )}
          {bottomProductsData?.products && bottomProductsData.products.length && (
            <LayoutContainer data-qa-product-carousel="">
              <Remote
                widget="product-carousel/root"
                data={{
                  seeMoreLink: bottomProductsData.link,
                  title: bottomProductsData.title,
                  productsIds: convertProducts(bottomProductsData.products),
                  elementBlock: bottomProductsData.gaPosition,
                }}
                id={bottomProductsData.gaPosition}
                className={remoteCarousel}
              />
            </LayoutContainer>
          )}
          {isGgrActive && (
            <LayoutContainer data-qa="turnkey-solution">
              <GGR {...turnkeyCardData} />
            </LayoutContainer>
          )}
          <LayoutContainer data-qa-seo-carousel="">
            <SeoCarousel catalogueJson={catalogue} />
          </LayoutContainer>
          <LayoutContainer data-qa-seo-text="">
            <SeoText seoTextData={seoTextData} />
          </LayoutContainer>
          <FooterLayoutContainer>
            <Remote
              widget="header-footer/footer"
              id="main-footer"
              data={{
                pathName: hfPathName,
              }}
            />
          </FooterLayoutContainer>
        </>
      )}
    </>
  );
});
