import { combineReducers } from '@reduxjs/toolkit';
import { GlobalRouter } from '@okapi/router-react';
import { createReducer as createRouterReducer } from '@okapi/router-connect';
import { featuresReducer } from 'features';
import { Layers, initReducer } from './utils';
import { Env } from './types';

export const rootReducer = ({
  cookie,
  env,
  globalRouter,
  host,
  layers,
  userAgent,
  isBot = false,
  uuid,
}: {
  cookie: string;
  env: Env;
  globalRouter: GlobalRouter;
  host: string | null;
  layers: Layers;
  userAgent: string | null;
  isBot?: boolean;
  uuid: string | null;
}) =>
  combineReducers({
    app: featuresReducer,
    cookie: initReducer(cookie),
    env: initReducer(env),
    host: initReducer(host),
    layers: initReducer(layers),
    router: createRouterReducer(globalRouter),
    userAgent: initReducer(userAgent),
    isBot: initReducer(isBot),
    uuid: initReducer(uuid),
  });

export type RootReducer = ReturnType<ReturnType<typeof rootReducer>>;
