import { getGlobalRouter } from '@okapi/router-react';

const router = getGlobalRouter();
const path = router.getPath();

export function patchUrlByLocale(url = '', pathname = '') {
  if (url.startsWith('https://')) {
    return url;
  }

  if (pathname?.startsWith('/kz/') && !url.startsWith('/kz')) {
    return `/kz${url}`;
  }

  if (path.startsWith('/kz/') && !url.startsWith('/kz')) {
    return `/kz${url}`;
  }

  return url;
}
