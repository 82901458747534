export const isSmall = (mobile: boolean, tablet: boolean, itemsCount: number, itemIndex: number) => {
  if (itemsCount === 2 && mobile) {
    return true;
  }

  if (itemsCount === 3) {
    return !mobile || (mobile && itemIndex > 0);
  }
  if (itemsCount === 4) {
    return true;
  }
  if (itemsCount === 5) {
    if (tablet) {
      return itemIndex < 3;
    }
    return !mobile || (mobile && itemIndex > 0);
  }
  return false;
};

export const getColumnsCount = (mobile: boolean, table: boolean, itemsCount: number) => {
  if (itemsCount === 3 && mobile) {
    return 2;
  }
  if ((itemsCount === 4 || itemsCount === 5) && mobile) {
    return 2;
  }
  if (table && itemsCount === 5) {
    return 4;
  }

  return itemsCount;
};

export const getColumnSpan = (mobile: boolean, tablet: boolean, itemsCount: number, itemIndex: number) => {
  if ((itemsCount === 3 || itemsCount === 5) && itemIndex === 0 && mobile) {
    return '1 / 3';
  }
  if (tablet && itemsCount === 5) {
    if (itemIndex === 1) {
      return '2 / 4';
    }
    if (itemIndex === 3) {
      return '1 / 3';
    }
    if (itemIndex === 4) {
      return '3 / 5';
    }
  }
  return 'initial';
};
