import { KV } from 'types/utils';

export type CustomParser<T> = {
  from: (query: KV) => T;
  to: (object: T) => KV;
};

function parse(query: string): KV;
function parse<T>(query: string, parser: CustomParser<T>): T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parse(query: string, parser?: any): any {
  const fromString: KV = {};

  new URLSearchParams(query).forEach((v, k) => {
    fromString[k] = v;
  });

  return parser ? parser.from(fromString) : fromString;
}

export const queryString = {
  parse,
};
