import { css } from 'linaria';

export const smDisplayCard = css`
  display: flex;
  margin-bottom: var(--space-300);
`;

export const itemStyles = css`
  max-width: var(--layout-width);
  scroll-snap-align: start;
  padding: 0 !important;
  border: none;
  --button-bg: var(--background-secondary) !important;
  --button-hover-bg: var(--control-minor) !important;
  --translate-distance: 0 !important;

  &:only-child {
    background-color: #ffef77;
  }
  @media (--from-tablet) {
    &:first-of-type {
      background-color: #ffef77;
    }
  }
`;
