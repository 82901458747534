import { css } from 'linaria';
import { createAdaptiveComponent } from './create-adaptive-component';

const fromTablet = css`
  display: none;

  @media (--from-tablet) {
    display: initial;
  }
`;

const atTablet = css`
  display: none;

  @media (--only-tablet) {
    display: initial;
  }
`;

const toTablet = css`
  display: none;

  @media (--only-mobile) {
    display: initial;
  }

  @media (--only-tablet) {
    display: initial;
  }
`;

export const FromTablet = createAdaptiveComponent({
  match: ({ mobile }) => !mobile,
  displayName: 'FromTablet',
  ssrClassName: fromTablet,
});

export const AtTablet = createAdaptiveComponent({
  match: ({ tablet }) => tablet,
  displayName: 'AtTablet',
  ssrClassName: atTablet,
});

export const ToTablet = createAdaptiveComponent({
  match: ({ mobile, tablet }) => mobile || tablet,
  displayName: 'ToTablet',
  ssrClassName: toTablet,
});
