import { css } from 'linaria';

export const wrapperStyles = css`
  width: 100vw;
  max-width: 1180px;
  padding: 0 var(--layout-gap);
  margin: 0 auto;
  @media (--only-desktop) {
    width: 100%;
    max-width: 940px;
  }
`;

export const promoCarouselWrapperStyles = css`
  width: 100vw;
  max-width: 1180px;
  margin: 0 auto;
  @media (--only-desktop) {
    width: 100%;
    max-width: 940px;
    padding: 0;
  }
  [data-qa-carousel=''] {
    @media (--only-mobile) {
      padding: 0 var(--layout-gap);
    }
    @media (--only-tablet) {
      padding: 0 calc((100vw - var(--layout-width) - 2 * var(--layout-gap)) / 2 + var(--layout-gap));
      scroll-padding: calc((100vw - var(--layout-width) - 2 * var(--layout-gap)) / 2 + var(--layout-gap));
    }
  }
`;

export const carouselWrapperStyles = css`
  width: 100vw;
  max-width: 1180px;
  margin: 0 auto;
  @media (--only-tablet) {
    max-width: calc(100% - 2 * var(--layout-gap));
    padding: 0 var(--layout-gap);
  }
  @media (--only-desktop) {
    width: 100%;
    max-width: 940px;
    padding: 0;
  }
  [data-qa-carousel=''] {
    @media (--only-mobile) {
      padding: 0 var(--layout-gap);
    }
    &.extendMobilePadding {
      @media (--only-mobile) {
        padding-right: calc(100% - 73vw - var(--layout-gap));
      }
    }
  }
`;
