import { range } from './range';

export const DEFAULT_RU_REGION = 'moscow';
export const DEFAULT_KZ_REGION = 'kazakhstan';

export const RU_DOMAINS = [
  'adeo.com',
  'leroymerlin.ru',
  'localhost',
  'lmru.tech',
  'lemonlid.ru',
  'lemanapro.ru',
];
export const KZ_DOMAINS = ['lemanapro.kz', 'lmkz.tech', 'leroymerlin.kz'];

const isDomainValid = (domain: string, domains: string[]) =>
  domains.some((matchingDomain) => domain === matchingDomain);

export function getHostname(host = '') {
  return host.replace(/:[0-9]+/, '');
}

export const getDomain = (hostname: string) => {
  let result = '';
  let domainSeparator = '.';

  const localhost = 'localhost';
  const start = hostname.indexOf(domainSeparator);

  const isIpAddress =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g.test(
      hostname,
    );

  if (isIpAddress) {
    return hostname;
  }

  if (hostname.endsWith(localhost)) {
    const splitted = hostname.split('.');

    return splitted[splitted.length - 1];
  }

  if (start !== -1) {
    const split = hostname.split(domainSeparator);

    result = hostname
      .split(domainSeparator)
      .slice(split.length - 2)
      .join(domainSeparator);
  }

  return result;
};

export const getRegion = (host = '') => {
  const hostname = host.replace(/:[0-9]+/, '');
  const allFsStands = range(20).map((index) => `fs${index}`);
  const definitelyNotARegion = [
    ...allFsStands,
    'aem',
    'arulm',
    'author',
    'dispatcher',
    'internal',
    'lemanapro',
    'lemonlid',
    'leroymerlin',
    'localhost',
    'mf',
    'mf-capella-a',
    'mf-capella-a-stage',
    'mf-taurus-a',
    'publish',
    'triffle',
    'ux',
    'yandex',
  ];
  const isFeatureStand = hostname.search(/mf-(navi-)*[0-9]+/);

  const domain = getDomain(hostname);
  const isRuDomain = isDomainValid(domain, RU_DOMAINS);
  const isKZDomain = isDomainValid(domain, KZ_DOMAINS);

  if (isRuDomain || isKZDomain) {
    const [maybeRegion] = hostname.split('.');

    if (!definitelyNotARegion.some((prefix) => maybeRegion.startsWith(prefix)) && isFeatureStand === -1) {
      return maybeRegion;
    }
  }

  if (isRuDomain) {
    return DEFAULT_RU_REGION;
  }

  if (isKZDomain) {
    return DEFAULT_KZ_REGION;
  }

  return '';
};
