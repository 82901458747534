import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ModelJsonContent } from '../types';
import { getModel } from './api';

type InitialState = { isError: boolean; data: ModelJsonContent | null };

const initialState: InitialState = { isError: false, data: null };

const fetchModelThunk = createAsyncThunk('pageModel/FETCH_MODEL', getModel);

const { actions, reducer } = createSlice({
  name: 'pageModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelThunk.pending, (state) => {
        state.isError = false;
      })
      .addCase(fetchModelThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.isError = false;
      })
      .addCase(fetchModelThunk.rejected, (state) => {
        state.isError = true;
      });
  },
});

export { actions, reducer, fetchModelThunk };
