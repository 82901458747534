import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ShockProduct } from 'features/page-model';
import { Typography } from 'fronton-react';
import {
  getShockProductsMobileView,
  SHOCK_PRODUCTS_EXPERIMENT,
  SHOCK_PRODUCTS_MOBILE_VIEW,
} from 'features/page-model/ducks/get-model';
import {
  Card,
  PriceContent,
  discountHeader,
  ImageContainer,
  productTitle,
  hideImageContainer,
  smallImageContainer,
  priceContentMobile,
} from './styles';
import { BottomPanel } from './components/bottom-panel';
import { Price } from './components/price';
import { Picture } from './components/picture';

export const DATA_QA_SHOCK_PRODUCT_ITEM = 'shock-price-product-item';
export const DATA_QA_DISCOUNT = 'shock-price-product-item-discount';

type Props = {
  item: ShockProduct;
  small: boolean;
  columnSpan: string;
};

export const ShockProductItem: FC<Props> = ({ item, small, columnSpan }) => {
  const { discount, price, previousPrice, imageUrl, title, uom, url } = item;
  const isShockProductsMobileView = useSelector(getShockProductsMobileView);
  let urlToProduct = `${url}?${SHOCK_PRODUCTS_EXPERIMENT}`;

  if (isShockProductsMobileView) urlToProduct += `&${SHOCK_PRODUCTS_MOBILE_VIEW}`;

  return (
    <Card small={small} columnSpan={columnSpan} href={urlToProduct} data-qa={DATA_QA_SHOCK_PRODUCT_ITEM}>
      <ImageContainer className={small ? hideImageContainer : undefined} />
      <Typography variant="h1" className={discountHeader} data-qa={DATA_QA_DISCOUNT}>
        -{discount}%
      </Typography>
      <ImageContainer className={small ? smallImageContainer : undefined}>
        <Picture alt={title} src={imageUrl} />
      </ImageContainer>
      <PriceContent className={small ? priceContentMobile : undefined}>
        <Price price={price} previousPrice={previousPrice} uom={uom} />
        <Typography variant="caption" className={productTitle}>
          {title}
        </Typography>
      </PriceContent>
      <BottomPanel />
    </Card>
  );
};
