import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from '@okapi/router-react';
import { memo } from 'hocs';
import { patchPath } from 'utils/patch-path';
import { Route, routes } from './routes';

const Routes = memo(function Routes() {
  return <NestedRoutes routesTree={routes} />;
});

const NestedRoutes: React.FC<{ routesTree: Route[] | undefined }> = ({ routesTree }) => (
  <Switch>
    {routesTree?.map(({ routes: nestedRoutes, component: Component, path, key, strict, exact }) => (
      <ReactRoute path={patchPath(path)} key={key} strict={strict} exact={exact}>
        {Component ? (
          <Component>
            <NestedRoutes routesTree={nestedRoutes} />
          </Component>
        ) : (
          <NestedRoutes routesTree={nestedRoutes} />
        )}
      </ReactRoute>
    ))}
    <ReactRoute path="*">
      <Redirect to="/404" />
    </ReactRoute>
  </Switch>
);

export { routes, Routes };
