import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { HrefLang, RegionWithLangs } from '@navi-toolbox/seo';
import { getHost } from 'features/page-model/ducks/get-model';
import { HelmetContext } from '@okapi/helmet-react';

type Props = {
  context: HelmetContext;
};

export const HrefLangMeta: FC<Props> = ({ context }) => {
  const regions: RegionWithLangs[] = [
    {
      regionCodeIS03166: 'ru',
      languagesISO639: ['ru'],
    },
    {
      regionCodeIS03166: 'kz',
      languagesISO639: ['ru', 'kk'],
    },
  ];
  const host = useSelector(getHost);
  const protocol = host?.includes('localhost') ? 'http://' : 'https://';

  return <HrefLang regions={regions} url={`${protocol + host}/`} context={context} />;
};
