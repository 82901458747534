import { css } from 'linaria';

export const seoText = css`
  & {
    display: block;
    max-width: 1200px;
    padding-right: var(--layout-gap);
    padding-left: var(--layout-gap);
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-70);
    @media (--only-tablet) {
      width: calc(var(--layout-width) + 2 * var(--layout-gap));
    }
  }

  & * {
    padding: 0;
    margin: 0;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 7px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
  }

  & h1:not(:first-child),
  & h2:not(:first-child),
  & h3:not(:first-child),
  & h4:not(:first-child),
  & h5:not(:first-child),
  & h6:not(:first-child) {
    margin-top: 40px;
  }

  & ul {
    margin: 5px 13px;
    list-style: disc;
  }

  & a {
    text-decoration: none;
    color: var(--color-text-70);
  }

  & a:hover,
  & a:active {
    color: var(--color-text);
  }
`;
