import React from 'react';
import { Helmet } from '@okapi/helmet-react';

export type YandexMetricaScriptProps = {
  id?: string | null;
};

export const YandexMetricaScript: React.FC<YandexMetricaScriptProps> = ({ id }) =>
  id ? (
    <Helmet>
      <script>
        {`!function(e,a,t,c,n){e.ym=e.ym||function(){(e.ym.a=e.ym.a||[]).push(arguments)},e.ym.l=+new Date,c=a.createElement(t),n=a.getElementsByTagName(t)[0],c.async=1,c.src="https://mc.yandex.ru/metrika/tag.js",n.parentNode.insertBefore(c,n)}(window,document,"script"),ym(${id},"init",{clickmap:!0,trackLinks:!0,accurateTrackBounce:!0,trackHash:!0,ecommerce:"yaLayer"});`}
      </script>
    </Helmet>
  ) : null;
