import React from 'react';
import { memo } from 'hocs/memo';
import { cn } from 'utils/classnames';
import { seoText } from './seo-text.styles';

export type SeoTextProps = {
  className?: string;
  style?: React.CSSProperties;
  seoTextData?: string | null;
};

export const SeoText = memo<SeoTextProps>(({ className, seoTextData, ...props }) => {
  return seoTextData ? (
    <div className={cn(seoText, className)} {...props} dangerouslySetInnerHTML={{ __html: seoTextData }} />
  ) : (
    <></>
  );
});
