import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { legalText } from './styles';

export const DATA_QA_LEGAL_TEXT = 'shock-price-legal-text';
export const DATA_QA_LEGAL_URL = 'shock-price-legal-url';

type Props = {
  text: string;
  url: string;
  urlLabel: string;
};

export const LegalText: FC<Props> = ({ text, urlLabel, url }) => (
  <Typography variant="caption" className={legalText} data-qa={DATA_QA_LEGAL_TEXT}>
    {text}{' '}
    <a href={url} data-qa={DATA_QA_LEGAL_URL}>
      {urlLabel}
    </a>
  </Typography>
);
