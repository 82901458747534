import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const information = css`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 350px;
  margin-right: 150px;
  ${mediaSize.forDesktopOnly} {
    margin-right: 50px;
  }
  ${mediaSize.forTabletOnly} {
    margin-top: 32px;
  }
  ${mediaSize.forMobileOnly} {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
`;

export const header = css`
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  ${mediaSize.forMobileOnly} {
    font-size: 22px;
  }
`;

export const description = css`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 12px 15px 32px 0px;
  font-size: 16px;
  line-height: 22px;
  ${mediaSize.forMobileOnly} {
    max-width: 100%;
    padding: 12px 0px 32px 0px;
    margin-right: 0;
    font-size: 14px;
    line-height: 18px;
  }
`;
