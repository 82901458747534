import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ElementType } from 'react';

export const Container: ElementType = styled.div<{ columnsCount: number; itemsCount: number }>`
  --big-column-width: 580px;
  --small-column-width: 220px;
  --small-column: minmax(auto, var(--small-column-width));

  display: grid;
  grid-gap: var(--space-250);
  grid-template-columns: repeat(
    ${({ columnsCount }) => columnsCount},
    ${({ itemsCount }) => (itemsCount < 3 ? 'minmax(auto, var(--big-column-width))' : 'var(--small-column)')}
  );
  margin-top: var(--space-400);

  @media (--only-tablet) {
    margin-top: var(--space-300);
    row-gap: var(--space-325);
  }

  @media (--only-mobile) {
    grid-gap: var(--space-150);
    row-gap: var(--space-225);
    margin-top: var(--space-375);

    --big-column-width: 328px;
  }
`;

export const tabletFiveProducts = css`
  grid-template-columns: var(--small-column) 1fr 1fr var(--small-column);
`;
