import { analyticsUtils } from '../../../utils/analytics-utils';

export const controlClickAnalytics = (value: number, direction?: string) => {
  if (direction) {
    let directionName = 'switch-backward';
    if (direction === 'right') {
      directionName = 'switch-forward';
    }
    analyticsUtils.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'divisions-subdivisions', // блок, в котором расположен элемент
      elementName: directionName, // условное обозначение
      elementText: directionName,
    });
  }
};
