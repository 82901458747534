const ru = require('./locales/ru.json');
const kk = require('./locales/kk.json');

module.exports = (lang = 'ru') => {
  return {
    debug: process.env.NODE_ENV !== 'production',
    lng: lang,
    fallbackLng: 'ru',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru,
      kk,
    },
  };
};
