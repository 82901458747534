import { styled } from 'linaria/react';
import { css } from 'linaria';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 350px;
  overflow: hidden;
  background-color: #ffed00;

  @media (--from-tablet) {
    height: 460px;
  }
`;

export const AnimationContainer = styled.div`
  display: contents;

  &::before {
    position: absolute;
    top: -160px;
    z-index: 0;
    width: 600px;
    height: 600px;
    background-image: url(https://cdn.lemanapro.ru/lmru/image/upload/v1712251457/fudzi/navigation-microfronts/shock-background.svg/shock-background.svg);
    background-repeat: no-repeat;
    background-size: contain;
    animation: animate-bg 8s linear infinite;
    content: '';

    @media (--only-tablet) {
      top: -422px;
      width: 1200px;
      height: 1200px;
    }
    @media (--from-desktop) {
      top: -765px;
      width: 2000px;
      height: 2000px;
    }

    @keyframes animate-bg {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  }
`;

export const logo = css`
  position: absolute;
  top: 73px;
  left: auto;
  right: auto;
  z-index: 100;

  @media (--only-tablet) {
    top: 110px;
  }
  @media (--from-desktop) {
    top: 72px;
  }
  @media (--from-large-desktop) {
    top: 44px;
  }
`;

export const limitedCount = css`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;

  @media (--only-tablet) {
    top: 50px;
    right: 80px;
  }
  @media (--from-desktop) {
    top: 284px;
    right: 228px;
    width: 121px;
    height: 71px;
  }
`;

export const everyFridayText = css`
  position: absolute;
  top: 60px;
  left: 15px;
  z-index: 100;
  font-style: oblique 10deg;
  transform: skew(0deg, -10deg);

  @media (--only-tablet) {
    top: 95px;
    left: 54px;
  }

  @media (--from-tablet) {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  @media (--from-desktop) {
    top: 190px;
    left: 145px;
    width: 160px;
    text-align: end;
  }
  @media (--from-large-desktop) {
    top: 200px;
    left: 226px;
  }
`;

export const MobileLines = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://cdn.lemanapro.ru/lmru/image/upload/v1712729765/fudzi/navigation-microfronts/mobile-lines.svg/mobile-lines.svg');
  background-repeat: no-repeat;

  @media (--from-tablet) {
    display: none;
  }
`;

export const DesktopLines = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://cdn.lemanapro.ru/lmru/image/upload/v1712729579/fudzi/navigation-microfronts/desktop-lines.svg/desktop-lines.svg');
  background-repeat: no-repeat;

  @media (--only-mobile) {
    display: none;
  }
`;

export const onlyInStore = css`
  position: absolute;
  top: 232px;
  transform: rotate(-10deg);
`;
