import { instaScroll } from '../scroll-to-position';

type OnScrollProps = {
  isBanner: boolean | undefined;
  currentElement: number;
  setCurrentElement: (element: number) => void;
  onChangeFn: ((change: number) => void) | undefined;
  elementGap: number;
  timer: NodeJS.Timeout | null;
  setTimer: (timer: NodeJS.Timeout | null) => void;
  scrollState: boolean;
  setScrollState: (scrollState: boolean) => void;
  checkScroll: () => void;
};

interface MakeScrollProps {
  clientWidth: number;
  elementGap: number;
  childrenLength: number;
  setScrollState: (state: boolean) => void;
  step: number;
  target: EventTarget | null;
  setCurrentElement: (element: number) => void;
  scrollLeft: number;
  onChangeFn?: (change: number) => void;
}

export const makeScroll = ({
  clientWidth,
  elementGap,
  childrenLength,
  setScrollState,
  step,
  target,
  setCurrentElement,
  scrollLeft,
  onChangeFn,
}: MakeScrollProps) => {
  const currentChildNum = Math.round(scrollLeft / (clientWidth + elementGap));
  if (currentChildNum < childrenLength / 3) {
    setScrollState(true);
    const newScroll = scrollLeft + (step * childrenLength) / 3;
    instaScroll(newScroll, target as HTMLDivElement).then(() => {
      setCurrentElement(currentChildNum + childrenLength / 3);
      if (onChangeFn) onChangeFn(newScroll);
      setScrollState(false);
    });
  }
  if (currentChildNum >= (2 * childrenLength) / 3) {
    let newScroll = scrollLeft - (step * childrenLength) / 3;
    let newElem = currentChildNum - childrenLength / 3;
    if (scrollLeft >= step * (childrenLength - 1)) {
      newScroll = step * (Math.floor((childrenLength * 2) / 3) - 1);
      newElem = Math.floor((childrenLength * 2) / 3) - 1;
    }
    instaScroll(newScroll - 1, target as HTMLDivElement).then(() => {
      instaScroll(newScroll, target as HTMLDivElement).then(() => {
        setCurrentElement(newElem);
        if (onChangeFn) onChangeFn(newScroll);
        setScrollState(false);
      });
    });
  }
};

export const onCarouselScroll =
  ({
    isBanner,
    currentElement,
    setCurrentElement,
    onChangeFn,
    elementGap,
    timer,
    setTimer,
    scrollState,
    setScrollState,
    checkScroll,
  }: OnScrollProps) =>
  ({ target }: Event) => {
    const { scrollLeft, children: targetChildren } = target as HTMLDivElement;
    if (isBanner) {
      const { clientWidth } = targetChildren[currentElement];
      const step = clientWidth + elementGap;
      const childrenLength = targetChildren.length;
      if (timer) window.clearTimeout(timer);
      if (scrollState) return;
      let newTimer: NodeJS.Timeout = setTimeout(
        () =>
          makeScroll({
            clientWidth,
            elementGap,
            childrenLength,
            setScrollState,
            step,
            target,
            setCurrentElement,
            scrollLeft,
            onChangeFn,
          }),
        100,
      );
      setTimer(newTimer);
    }
    if (!scrollState && onChangeFn) onChangeFn(scrollLeft);
    if (!isBanner) {
      checkScroll();
    }
  };
