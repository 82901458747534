import React, { useRef, FC, ElementType, ReactElement } from 'react';
import { RegularButton } from 'fronton-react';
import { analyticsUtils } from 'utils/analytics-utils';
import { useIntersectionObserverCallback } from 'hooks/use-intersection-observer-callback';
import { CarouselOffer } from 'pages/root-page/root-page.types';

export const DATA_QA_CAROUSEL_ITEM = 'carousel-item';

type ItemProps = {
  children: ReactElement;
  className?: string;
  href?: string;
  variant: 'primary' | 'secondary' | 'outline' | 'pseudo';
  as?: ElementType;
  data?: Partial<CarouselOffer> & { idx: number; elementBlock: string };
  onItemIntersect?: (idx: number) => void;
  withoutAnalytics?: boolean;
};

export const CarouselItem: FC<ItemProps> = ({
  children,
  className,
  href,
  variant,
  as = 'a',
  data,
  onItemIntersect,
  withoutAnalytics,
}) => {
  const carouselItemRef = useRef<HTMLDivElement | null>(null);
  useIntersectionObserverCallback(carouselItemRef, {
    enter: () => {
      if (onItemIntersect) onItemIntersect(data ? data.idx : 0);
      if (!withoutAnalytics) {
        analyticsUtils.sendEvent('GA:promoView', {
          elementType: 'banner',
          elementBlock: data?.elementBlock, // блок, в котором расположен элемент
          promotions: [
            {
              creative: data?.title || '', // добавляется при загрузке баннера через админку
              name: data?.gaName, // название блока с баннером (добавляется при загрузке баннера через админку)
              position: data?.idx, //  позиция баннера (добавляется при загрузке баннера через админку)
            },
          ],
        });
      }
    },
    extraUnobserve: 'enter',
  });

  const handleClick = () => {
    analyticsUtils.sendEvent('GA:promoClick', {
      elementType: 'banner',
      elementBlock: data?.elementBlock, // блок, в котором расположен элемент
      promotions: [
        {
          creative: data?.title || '', // добавляется при загрузке баннера через админку
          name: data?.gaName, // название блока с баннером (добавляется при загрузке баннера через админку)
          position: data?.idx, //  позиция баннера (добавляется при загрузке баннера через админку)
        },
      ],
    });
  };
  return (
    <RegularButton
      ref={carouselItemRef}
      as={as}
      onClick={handleClick}
      variant={variant}
      href={href}
      className={className}
      data-qa={DATA_QA_CAROUSEL_ITEM}
    >
      {children}
    </RegularButton>
  );
};
