import { useEffect } from 'react';

export type Params = {
  handle?: Handle;
};

type Handle = 'resize' | 'scroll' | 'both';

export const useVisualViewportApi = (
  callback: (viewport: VisualViewport) => void,
  { handle = 'both' }: Params = {},
) =>
  useEffect(
    () => subscribe((event: VisualViewportEvent) => callback(event.target), handle),
    [callback, handle],
  );

const subscribe = (cb: (event: VisualViewportEvent) => void, handle: Handle): (() => void) => {
  if ('visualViewport' in window) {
    if (['both', 'resize'].includes(handle)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.visualViewport.addEventListener('resize', cb);
    }

    if (['both', 'scroll'].includes(handle)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.visualViewport.addEventListener('scroll', cb);
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.visualViewport.removeEventListener('resize', cb);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.visualViewport.removeEventListener('scroll', cb);
    };
  }

  return () => {};
};
