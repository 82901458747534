import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'fronton-react';
import { ChevronUpIcon, ChevronDownIcon } from '@fronton/icons-react';
import { cn } from 'utils/classnames';
import { fadeButton, icon } from './fade-button.styles';
import { FadeButtonProps } from './fade-button.types';

export const FadeButton: FC<FadeButtonProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClick } = props;
  const direction = open ? 'bottom' : 'top';

  return (
    <Button
      className={cn(fadeButton)}
      kind="regular"
      variant="primary"
      size="l"
      rounded
      iconOnly
      invert
      label={open ? t('ui.open') : t('ui.close')}
      onClick={onClick}
      data-qa-fade-arrow-open={!open}
    >
      <div className={icon}>
        {direction === 'top' ? (
          <ChevronUpIcon type="outline" size="l" color="text-primary" />
        ) : (
          <ChevronDownIcon type="outline" size="l" color="text-primary" />
        )}
      </div>
    </Button>
  );
};
