import { css } from 'linaria';

export const seoControlButton = css`
  top: 86px;
  margin: 0 -40px;
`;

export const seoCarouselStyles = css`
  margin-top: var(--space-500);

  [data-qa-carousel=''] {
    scroll-padding: 0;
  }
`;
