import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ElementType } from 'react';

export const Container: ElementType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  white-space: nowrap;
  background-color: var(--secondary-yellow-light);
  box-shadow: var(--box-shadow-l);

  @media (--only-tablet) {
    top: 271px;
  }
  @media (--from-tablet) {
    top: 271px;
  }
`;

export const TextContainer = styled.span<{ animate: boolean }>`
  animation: ${({ animate }) => (animate ? 'ticker 15s linear infinite' : 'none')};

  @keyframes ticker {
    0% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const text = css`
  margin-right: var(--space-50);

  @media (--from-desktop) {
    font-size: 20px !important;
  }
`;

export const boldText = css`
  font-weight: 700 !important;
`;
