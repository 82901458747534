import { css } from 'linaria';

export const icon = css`
  width: 24px;
  height: 24px;
`;

export const fadeButton = css`
  --translate-distance: 0 !important;
  top: unset;
  left: calc(50% - 38px);
  bottom: -50px;
  min-width: 112px;
  max-height: 40px;
  background: var(--control-invert);
  border: none;
  border-radius: 120px;
  box-shadow: var(--box-shadow-m);

  &:hover {
    background: var(--control-invert);
  }
`;
