import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { getShockProducts } from 'features/page-model/ducks/get-model';
import { ShockLogo } from '../shock-logo';
import {
  AnimationContainer,
  Container,
  DesktopLines,
  MobileLines,
  everyFridayText,
  logo,
  limitedCount,
  onlyInStore,
} from './styles';
import { OnlyInStore } from '../only-in-store';
import { Title } from '../title';

import { LimitedCount } from '../limited-count';
import { DueDate } from '../due-date';

export const DATA_QA_TOP_BANNER = 'shock-price-top-banner';
export const DATA_QA_EVERY_FRIDAY = 'shock-price-every-friday';
export const DATA_QA_ANIMATED_ONLY_IN_STORE = 'shock-price-animated-only-in-store';

export const TopBanner: FC = () => {
  const { t } = useTranslation();
  const { informationTop = '', dueDate = '' } = useSelector(getShockProducts) || {};

  return (
    <Container data-qa={DATA_QA_TOP_BANNER}>
      <LimitedCount className={limitedCount} />
      <DueDate date={dueDate} />
      <Typography variant="s" size="body_accent" className={everyFridayText} data-qa={DATA_QA_EVERY_FRIDAY}>
        {t('shockPrice.eachFriday')}
      </Typography>
      <AnimationContainer>
        <ShockLogo className={logo} />
      </AnimationContainer>
      <OnlyInStore animate className={onlyInStore} dataQAId={DATA_QA_ANIMATED_ONLY_IN_STORE} />
      <Title title={informationTop} />
      <MobileLines />
      <DesktopLines />
    </Container>
  );
};
