import React, { useState } from 'react';
import { analyticsUtils } from 'utils/analytics-utils';
import { Carousel } from 'components/carousel-components/carousel';
import { SeoCard, SeoCardProp } from '../seo-card';
import { FadeButton } from '../fade-button';
import { seoControlButton, seoCarouselStyles } from './seo-carousel.styles';
import { FadeContainer } from '../fade-container';
import { PropsType } from './seo-carousel.types';
import { CarouselWrapper } from '../wrapper';
import { controlClickAnalytics } from './control-click-analytics';

export const DATA_QA_SEO_CAROUSEL_WRAPPER = 'seo-carousel-wrapper';

export const SeoCarousel = ({ catalogueJson }: PropsType) => {
  const [isFade, setFade] = useState(true);
  if (!catalogueJson || !catalogueJson.length) return null;
  const handleClick = () => {
    if (isFade) {
      analyticsUtils.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'divisions-subdivisions', // блок, в котором расположен элемент
        elementName: 'open', // условное обозначение
        elementText: 'open',
      });
    }
    setFade(!isFade);
  };
  const carouselHeight = { height: `${isFade ? '188px' : ''}` };

  return (
    <CarouselWrapper data-qa={DATA_QA_SEO_CAROUSEL_WRAPPER}>
      <Carousel
        className={seoCarouselStyles}
        style={carouselHeight}
        onChange={controlClickAnalytics}
        controlSettings={{
          className: seoControlButton,
          variant: 'secondary',
          size: 'l',
          rounded: true,
          iconOnly: true,
          iconSize: 16,
        }}
      >
        {catalogueJson?.map((item: SeoCardProp) => <SeoCard {...item} key={item.sitePath} />)}
      </Carousel>
      {isFade && <FadeContainer />}
      <FadeButton open={isFade} onClick={handleClick} />
    </CarouselWrapper>
  );
};
