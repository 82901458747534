import { css } from 'linaria';

export const shockPrice = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const previousPriceWrapper = css`
  position: relative;
  width: max-content;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: var(--space-10);
    background: #c90619;
    transform: translateY(-50%);
    content: '';
  }
`;

export const previousPriceClassname = css`
  font-weight: 600 !important;
  line-height: 24px !important;
  text-transform: uppercase;
  color: var(--text-primary) !important;
`;

export const previousPriceCurrency = css`
  margin-left: var(--space-25);
  color: var(--text-primary) !important;
`;

export const priceClassname = css`
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 32px !important;
  color: #000 !important;
`;

export const uomClassname = css`
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #000 !important;
`;
