import { styled } from 'linaria/react';
import { ElementType } from 'react';
import { css } from 'linaria';
import { pictureContainer } from './components/picture/styles';

export const Card: ElementType = styled.a<{ small: boolean; columnSpan: string }>`
  --columns-count: ${({ small }) => (small ? 1 : 2)};
  --red-header-height: ${({ small }) => (small ? '120px' : '54px')};
  position: relative;
  display: grid;
  grid-column: ${({ columnSpan }) => columnSpan};
  grid-template-columns: repeat(var(--columns-count), minmax(0, 1fr));
  grid-template-rows: var(--space-250) minmax(0, 1fr);

  text-decoration: none;
  border-radius: var(--space-150) var(--space-150) 0 0;
  box-shadow: var(--box-shadow-m);
  cursor: pointer;

  &::before {
    position: absolute;
    width: 100%;
    height: var(--red-header-height);
    background-color: #ff0020;
    border-radius: 12px 12px 0 0;
    content: '';
  }
`;

export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  height: 100%;
  padding: var(--space-150) var(--space-200) var(--space-150) 0;
  margin: var(--space-175) 0;
`;
export const ImageContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: var(--space-200);

  .${pictureContainer} {
    height: 100%;
    max-height: 258px;
  }
`;

export const productTitle = css`
  display: -webkit-box;
  margin-top: var(--space-100);
  overflow: hidden;
  white-space: pre-wrap;
  text-align: center;
  color: var(--text-primary);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const discountHeader = css`
  z-index: 100;
  padding-right: var(--space-200);
  margin-top: var(--space-125);
  font-size: 32px !important;
  text-align: center;
  color: #faff00;
`;

export const hideImageContainer = css`
  display: none;
`;

export const smallImageContainer = css`
  padding-left: 0;
  margin-top: var(--space-375);

  .${pictureContainer} {
    height: 100%;
    max-height: 180px;
  }
`;

export const priceContentMobile = css`
  padding: 0 var(--space-200) var(--space-200);
  margin-top: 0;
`;
