import { css } from 'linaria';
import { styled } from 'linaria/react';

export const LeftSide = styled.div`
  width: 7px;
  height: 27px;
  background-color: var(--background-primary);
  border: 1px solid var(--brand-minor);
  transform: skew(0deg, -23deg);
  transform-origin: right;

  @media (--from-desktop) {
    height: 34px;
  }
`;

export const BottomSide = styled.div`
  width: 100%;
  height: 4px;
  background-color: var(--background-primary);
  border: 1px solid var(--brand-minor);
  transform: skew(-65deg, 0deg) rotateX(-30deg);
  transform-origin: top;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  transform: skew(-10deg, -10deg);

  @media (--only-tablet) {
    top: 40px;
    left: 54px;
  }
  @media (--only-desktop) {
    top: 46px;
    left: 134px;
  }
  @media (--from-large-desktop) {
    top: 47px;
    left: 140px;
  }
`;

export const text = css`
  padding: var(--space-60) var(--space-125) !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-transform: uppercase;
  color: #54ff00;
  background-color: black;

  @media (--from-desktop) {
    font-size: 20px !important;
  }
`;
