import React from 'react';
import { useOptimizedImages } from 'hooks/use-optimized-images';
import { Props } from './promo-card.types';
import { card, cardTitle, cardDesc } from './promo-card.styles';

const rest = {
  w: '200',
  h: '200',
};
export const PromoCard = ({ size, text, title, image }: Props) => {
  const promoPhotos = useOptimizedImages(
    image,
    {
      rest,
    },
    true,
  );
  return (
    <div className={card} data-size={size}>
      <div className="imgWrap">
        <picture>
          <source srcSet={`${promoPhotos.rest?.xx} 2x, ${promoPhotos.rest?.x} 1x`} />
          <img
            alt="Изображение"
            src={promoPhotos.rest?.x ?? undefined}
            srcSet={`${promoPhotos.rest?.xx} 2x, ${promoPhotos.rest?.x} 1x`}
            width={rest.w}
            height={rest.h}
          />
        </picture>
      </div>
      <div>
        <div className={cardTitle} data-size={size}>
          {title}
        </div>
        <div className={cardDesc} data-size={size}>
          {text}
        </div>
      </div>
    </div>
  );
};
