import { styled } from 'linaria/react';
import { css } from 'linaria';

export const Container = styled.div`
  position: absolute;
  bottom: -10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`;

export const item = css`
  flex: 1 0 auto;
  margin-left: -1px;

  &:first-child {
    margin-left: 0;
  }
`;
