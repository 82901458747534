import React, { FC } from 'react';
import { ShockProduct } from 'features/page-model';
import { useBreakpoints } from 'hooks';
import { ShockProductItem } from './components/shock-product-item';
import { Container, tabletFiveProducts } from './styles';
import { getColumnsCount, getColumnSpan, isSmall } from './utils';

export const DATA_QA_SHOCK_PRODUCTS = 'shock-price-products';

type Props = {
  items: ShockProduct[];
};

export const ShockProducts: FC<Props> = ({ items }) => {
  const { mobile = false, tablet = false } = useBreakpoints() || {};
  const itemsCount = items.length;

  if (!itemsCount) {
    return null;
  }

  return (
    <Container
      data-qa={DATA_QA_SHOCK_PRODUCTS}
      itemsCount={itemsCount}
      columnsCount={getColumnsCount(mobile, tablet, itemsCount)}
      className={itemsCount === 5 && tablet ? tabletFiveProducts : undefined}
    >
      {items.map((item, index) => (
        <ShockProductItem
          item={item}
          key={item.id}
          small={isSmall(mobile, tablet, itemsCount, index)}
          columnSpan={getColumnSpan(mobile, tablet, itemsCount, index)}
        />
      ))}
    </Container>
  );
};
