import React, { Context } from 'react';
import { Provider as RemoteProvider } from '@okapi/remote';
import { Router } from '@okapi/router-react';
import { Provider as HelmetProvider, Helmet, HelmetContext } from '@okapi/helmet-react';
import { Provider, ReactReduxContextValue } from 'react-redux';
import { getAnalytics, getSeoProperties } from 'features/page-model/ducks/get-model';
import { GoogleAnalyticsScript } from 'components/google-analytics-script';
import { YandexMetricaScript } from 'components/yandex-metrika-script';
import { initI18n } from 'utils/init-i18n';
import { MetaRobots } from '@navi-toolbox/seo';
import { App } from 'app';
import { HrefLangMeta } from 'pages/root-page/href-lang-meta';
import { EntryProps } from './entry.types';

export function createEntry({ store, router, helmetContext, remoteContext }: EntryProps) {
  return () => {
    const state = store.getState();
    const { gtmCode, yaCode } = getAnalytics(state);
    const lang = initI18n(router.getPath(), state.host);
    const seoProperties = getSeoProperties(state);
    const context = helmetContext as HelmetContext & Context<ReactReduxContextValue>;

    return (
      <Router router={router}>
        <RemoteProvider context={remoteContext}>
          <HelmetProvider context={helmetContext}>
            <Provider store={store}>
              <App />
              <MetaRobots content={seoProperties?.metaRobotsContent || []} context={context} />
              <HrefLangMeta context={context} />
              <Helmet>
                <html lang={lang} />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
                <meta content="#ffffff" name="theme-color" />
                <link href="//fonts.googleapis.com" rel="dns-prefetch" />
                <link href="https://cdn.lemanapro.ru/" crossOrigin="anonymous" rel="preconnect" />
                <link href="https://www.googletagmanager.com" crossOrigin="anonymous" rel="preconnect" />
                <link href="https://www.google-analytics.com" crossOrigin="anonymous" rel="preconnect" />
                <link href="https://fonts.gstatic.com" crossOrigin="anonymous" rel="preconnect" />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_400_cyr.woff2/file.woff2"
                />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_400_lat.woff2/file.woff2"
                />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_500_cyr.woff2/file.woff2"
                />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_500_lat.woff2/file.woff2"
                />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_700_cyr.woff2/file.woff2"
                />
                <link
                  rel="preload"
                  as="font"
                  crossOrigin="anonymous"
                  href="https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/fonts/roboto_700_lat.woff2/file.woff2"
                />
              </Helmet>
              <GoogleAnalyticsScript id={gtmCode} />
              <YandexMetricaScript id={yaCode} />
            </Provider>
          </HelmetProvider>
        </RemoteProvider>
      </Router>
    );
  };
}
