import React from 'react';

export const renderExtra = (elements: JSX.Element[], direction: 'prev' | 'next') => {
  let output: JSX.Element[] = [];
  const length = elements && elements.length ? elements.length : 0;
  if (!length) return [];
  for (let index = 0; index < length; index += 1) {
    let extraItem = <></>;
    if (direction === 'next') {
      extraItem =
        elements && elements[index] ? (
          React.cloneElement(elements[index], { withoutAnalytics: true })
        ) : (
          <></>
        );
    }
    if (direction === 'prev') {
      extraItem =
        elements && elements[length - 1 - index] ? (
          React.cloneElement(elements[length - 1 - index], { withoutAnalytics: true })
        ) : (
          <></>
        );
    }
    output.push(extraItem);
  }
  if (direction === 'prev') output.reverse();
  return output;
};
