import isEqual from 'react-fast-compare';
import { EntryForKey, IntersectionObserverOptions, PotentialRootEntry } from './types';

/**
 * Determine if existing elements for a given root based on passed in options
 * regardless of sort order of keys
 *
 * @param {Object} options
 * @param {Object} potentialRootMatch e.g. { stringifiedOptions: { elements: [], ... }, stringifiedOptions: { elements: [], ... }}
 * @return {Object} containing array of elements and other meta
 */
export const determineMatchingElements = (
  options: IntersectionObserverOptions,
  potentialRootMatch: PotentialRootEntry,
): EntryForKey | undefined => {
  const matchingKey = Object.keys(potentialRootMatch).filter((key) => {
    const { options: comparableOptions } = potentialRootMatch[key];
    return isEqual(options, comparableOptions);
  })[0];
  return potentialRootMatch[matchingKey];
};
