import React from 'react';
import { Helmet } from '@okapi/helmet-react';
import { ModelJsonContent } from 'features/page-model';
import { memo } from '../../hocs';

export type PageMetaProps = {
  modelJson: ModelJsonContent;
};
export const PageMeta: React.FC<PageMetaProps> = memo(({ modelJson }) => {
  return modelJson ? (
    <>
      <Helmet>
        <title>{modelJson.seoProperties?.pageTitle}</title>
        <meta name="keywords" content={modelJson.seoProperties?.metakeywords ?? undefined} />
        <meta name="description" content={modelJson.seoProperties?.metadescription ?? undefined} />
        <link rel="canonical" href={modelJson.seoProperties?.canonicalURL ?? '/'} />
        <meta property="og:title" content={modelJson.seoProperties?.pageTitle ?? ''} />
        {modelJson.seoProperties?.metadescription && (
          <meta property="og:description" content={modelJson.seoProperties?.metadescription} />
        )}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={modelJson.header?.logoImage ?? ''} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Helmet>
        <link href={modelJson.header?.faviconImage ?? ''} rel="shortcut icon" type="image/x-icon" />
      </Helmet>
    </>
  ) : null;
});
