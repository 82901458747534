import { DependencyList } from 'react';
import {
  intersectionObserverController,
  IntersectionObserverOptions,
} from 'utils/intersection-observer-controller';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

export type Options = IntersectionObserverOptions;

type ObserveType = 'enter' | 'exit';
type Intersection = {
  enter?: () => void;
  exit?: () => void;
  extraUnobserve?: ObserveType;
  options?: Options;
};

/**
 * The Intersection Observer API provides a way to asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document's viewport
 * You can use callbacks as enter or exit
 * @param ref
 * @param intersection
 * @param deps
 */
export const useIntersectionObserverCallback = (
  ref: React.RefObject<HTMLElement>,
  intersection: Intersection = {
    options: { threshold: 0, root: null, rootMargin: '0%' },
  },
  deps: DependencyList[] | unknown[] = [],
) => {
  useIsomorphicLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      if (intersection.enter) {
        intersectionObserverController.addEnterCallback(current, () => {
          if (intersection.enter) {
            intersection.enter();
          }

          if (intersection.extraUnobserve && intersection.extraUnobserve === 'enter') {
            intersectionObserverController.unobserve(current, intersection.options);
          }
        });
      }

      if (intersection.exit) {
        intersectionObserverController.addExitCallback(current, () => {
          if (intersection.exit) {
            intersection.exit();
          }
          if (intersection.extraUnobserve && intersection.extraUnobserve === 'exit') {
            intersectionObserverController.unobserve(current, intersection.options);
          }
        });
      }

      intersectionObserverController.observe(current, intersection.options);
    }
    return () => {
      if (current) {
        intersectionObserverController.unobserve(current, intersection.options);
      }
    };
  }, [...deps]);
};
