import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { useBreakpoints } from 'hooks';
import { Container, text } from './styles';

export const DATA_QA_TITLE = 'shock-price-title';

type Props = {
  title: string;
};

export const Title: FC<Props> = ({ title }) => {
  const { mobile } = useBreakpoints() || {};

  if (!title) {
    return null;
  }

  return (
    <Container data-qa={DATA_QA_TITLE}>
      <Typography variant={mobile ? 'h3' : 'h2'} className={text}>
        {title}
      </Typography>
    </Container>
  );
};
