import React, { FC } from 'react';
import { information, header, description } from './info-container.styles';
import { InfoContainerProps } from './info-container.types';

export const InfoContainer: FC<InfoContainerProps> = ({ cardHeader, cardDescription, children }) => (
  <div className={information}>
    <div className={header}>{cardHeader}</div>
    <div className={description}>{cardDescription}</div>
    {children}
  </div>
);
