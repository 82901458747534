import React from 'react';
import { Button } from 'fronton-react';
import { ChevronLeftIcon, ChevronRightIcon } from '@fronton/icons-react';
import { cn } from 'utils/classnames';
import { ButtonLabeled, Props } from './carousel-control.types';
import { buttonStyle, icon } from './carousel-control.styles';

export const CarouselControl: React.FC<Props> = ({
  direction = 'left',
  onClick,
  variant,
  size,
  rounded,
  iconOnly,
  label,
  iconSize,
  className,
  ...props
}) => {
  return (
    <Button
      className={cn(className, buttonStyle, direction === 'left' ? 'left' : 'right')}
      kind="regular"
      variant={variant}
      size={size}
      {...(rounded && { rounded: true })}
      {...(iconOnly && { iconOnly: true })}
      label={label || ''}
      onClick={onClick}
      invert
      {...(props as ButtonLabeled)}
    >
      <div className={icon}>
        {direction === 'left' ? (
          <ChevronLeftIcon type="outline" size={size} color="text-primary" />
        ) : (
          <ChevronRightIcon type="outline" size={size} color="text-primary" />
        )}
      </div>
    </Button>
  );
};
