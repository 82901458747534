import { css } from 'linaria';

export const mediaMobile = 'only screen and (max-width: 767px)';
export const mediaMoreThenMobile = 'only screen and (min-width: 768px)';
export const mediaDesktop = 'only screen and (min-width: 1024px)';

export const pictureContainer = css`
  overflow: hidden;

  @media (--only-mobile) {
    max-height: 159px !important;
  }
`;

export const image = css`
  width: 100%;
  height: 100%;
`;
