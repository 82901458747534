import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { RowContainer, LeftSide, text, ColumnContainer, BottomSide } from './styles';

export const DATA_QA_DUE_DATE = 'shock-price-due-date';

type Props = {
  date: string;
};

export const DueDate: FC<Props> = ({ date }) => {
  if (!date) {
    return null;
  }
  return (
    <RowContainer data-qa={DATA_QA_DUE_DATE}>
      <LeftSide />
      <ColumnContainer>
        <Typography variant="s" size="body_accent" className={text}>
          {date}
        </Typography>
        <BottomSide />
      </ColumnContainer>
    </RowContainer>
  );
};
