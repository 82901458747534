import React, { FC } from 'react';
import { patchUrlByLocale } from 'utils';
import { analyticsUtils } from 'utils/analytics-utils';
import { SubProps } from './sub-department.types';
import { ListItem, RowLink } from './sub-department.styles';

export const DATA_QA_SUB_DEPARTMENT = 'seo-card-sub-department';
export const DATA_QA_SUB_DEPARTMENT_ROW_CLICK = 'sub-department-row-click';

export const SubDepartment: FC<SubProps> = (subProps) => {
  const { name, sitePath } = subProps;
  const handleSubDepClick = (subDepName: string) => () => {
    analyticsUtils.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'divisions-subdivisions', // блок, в котором расположен элемент
      elementName: 'subdivision', // условное обозначение
      elementText: subDepName, // название отдела
    });
  };
  return (
    <ListItem data-qa={DATA_QA_SUB_DEPARTMENT}>
      <RowLink
        href={patchUrlByLocale(sitePath)}
        onClick={handleSubDepClick(name)}
        data-qa={DATA_QA_SUB_DEPARTMENT_ROW_CLICK}
      >
        {name}
      </RowLink>
    </ListItem>
  );
};
