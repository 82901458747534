import React, { useState } from 'react';
import { patchUrlByLocale } from 'utils';
import { promoCarouselWrapperStyles, wrapperStyles } from 'styles/common-classes';
import { useIsomorphicLayoutEffect } from 'hooks/use-isomorphic-layout-effect';
import { itemStyles, smDisplayCard } from './promo-carousel.styles';
import { PromoCard } from '../promo-card';
import { CarouselItem } from '../../carousel-components/carousel-item';
import { Carousel } from '../../carousel-components/carousel';
import { InputContent } from './promo-carousel.types';

export const PromoCarousel = ({ content }: InputContent) => {
  const [...itemsData] = content;
  const [width, setWidth] = useState<number>(!__IS_SERVER__ ? window.outerWidth : 0);
  const [displayLarge, setDisplayLarge] = useState(false);
  const [hideControls, setHideControls] = useState(false);

  useIsomorphicLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    const windowSize = {
      mobileMin: 320,
      mobileMax: 767,
      tabletMax: 1023,
    };
    if (width >= windowSize.mobileMin && width <= windowSize.mobileMax) {
      setDisplayLarge(true);
    } else {
      setDisplayLarge(false);
    }
    if (width <= windowSize.tabletMax) {
      setHideControls(true);
    } else {
      setHideControls(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <>
      {displayLarge && (
        <div className={wrapperStyles}>
          <div className={smDisplayCard}>
            <CarouselItem
              key={`CarouselItem-${itemsData[0].url}`}
              href={patchUrlByLocale(itemsData[0].url)}
              data={{ ...itemsData[0], idx: 0, elementBlock: 'promoBannersMainOffer' }}
              className={itemStyles}
              variant="secondary"
            >
              <PromoCard
                title={itemsData[0].title}
                text={itemsData[0].text}
                image={itemsData[0].image}
                size="lg"
              />
            </CarouselItem>
          </div>
        </div>
      )}
      <div className={promoCarouselWrapperStyles}>
        <Carousel
          hideControls={hideControls}
          stepQuantity={2}
          controlSettings={{
            variant: 'secondary',
            size: 'l',
            rounded: true,
            iconOnly: true,
            iconSize: 16,
          }}
        >
          {itemsData.map(({ url, title, text, image }, idx: number) =>
            idx > 0 || !displayLarge ? (
              <CarouselItem
                data={{
                  idx,
                  gaName: itemsData[idx].name,
                  offer: itemsData[idx].creative,
                  elementBlock: 'promoBannersMainOffer',
                }}
                key={`CarouselItem-${url}`}
                href={patchUrlByLocale(url)}
                className={itemStyles}
                variant="secondary"
              >
                <PromoCard
                  title={title}
                  text={text}
                  image={image}
                  size={!displayLarge && !idx ? 'lg' : 'sm'}
                />
              </CarouselItem>
            ) : (
              <></>
            ),
          )}
        </Carousel>
      </div>
    </>
  );
};
