import { styled } from 'linaria/react';
import { css } from 'linaria';

export const Container = styled.div`
  position: absolute;
  top: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 600px;
  background-color: var(--background-primary);
  transform: skew(0, -10deg);

  @media (--from-tablet) {
    top: 295px;
    padding-top: var(--space-650);
  }
`;

export const text = css`
  margin-top: var(--space-600);
  font-weight: 700 !important;
  font-size: 20px !important;
  text-align: center;
  transform: skew(0, 10deg);

  @media (--to-tablet) {
    width: 220px;
  }

  @media (--from-tablet) {
    width: 660px;
    margin-top: var(--space-1000);
  }
`;
