import { css } from 'linaria';
import React from 'react';
import { Match, useBreakpoints } from 'hooks/use-breakpoints';
import { cn } from 'utils/classnames';

export type AdaptivityProps = {
  /**
   * Контент будет отрендерен, в процессе SSR
   */
  ssrFallback?: React.ReactNode;
  /**
   * Контент, который будет отрендерен, если на текущем брейкпойнте компонент рендериться не должен
   */
  fallback?: React.ReactNode;
};

type WithAdaptiveOptions = {
  match: (matches: Match) => boolean;
  displayName?: string;
  ssrClassName?: string;
};

export const createAdaptiveComponent = ({ match, displayName, ssrClassName }: WithAdaptiveOptions) => {
  const AdaptiveComponent: React.FC<AdaptivityProps> = ({
    children,
    fallback = null,
    ssrFallback = children,
  }) => {
    const matches = useBreakpoints();

    if (matches === null) {
      return <span className={cn(ssrClassName, adaptiveComponentSSR)}>{ssrFallback}</span>;
    }

    return <>{match(matches) ? children : fallback}</>;
  };

  AdaptiveComponent.displayName = displayName;

  return AdaptiveComponent;
};

const adaptiveComponentSSR = css`
  display: contents;
`;
