import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { cx } from 'linaria';
import { boldText, Container, text, TextContainer } from './styles';

export const COUNT = 40;

type Props = {
  animate?: boolean;
  className?: string;
  textClassName?: string;
  dataQAId?: string;
};

export const OnlyInStore: FC<Props> = ({ animate = false, className, textClassName, dataQAId }) => {
  const { t } = useTranslation();

  return (
    <Container className={className} data-qa={dataQAId}>
      <TextContainer animate={animate}>
        {Array.from({ length: COUNT }).map((_, index) => (
          <Typography
            key={index}
            className={cx(text, textClassName, (index + 1) % 2 > 0 ? boldText : undefined)}
            variant="caption"
          >
            {t('shockPrice.shopOnly')}
          </Typography>
        ))}
      </TextContainer>
    </Container>
  );
};
