import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const card = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  padding: 24px;
  overflow: hidden;
  white-space: initial;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  & .imgWrap {
    position: absolute;
    right: 0;
    bottom: 0px;
  }
  &[data-size='sm'] {
    width: 180px;
    padding: 24px;
    background-position: right bottom;
    ${mediaSize.forDesktopOnly} {
      width: 220px;
    }
    ${mediaSize.forTabletOnly} {
      width: 156px;
      padding: 16px;
    }
    ${mediaSize.forMobileOnly} {
      width: 156px;
      padding: 16px;
    }
    & .imgWrap {
      width: 118px;
      height: 118px;
      overflow: hidden;
      & picture {
        display: block;
        width: 100%;
        height: 100%;
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &[data-size='lg'] {
    align-items: baseline;
    width: 380px;
    & .imgWrap {
      width: 100%;
      height: 100%;
      & picture {
        & img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 240px;
          object-fit: cover;
        }
      }
    }

    background-position: calc(100% - 15px) bottom;
    ${mediaSize.forDesktopOnly} {
      width: 460px;
      & .imgWrap {
        right: calc(0% - 15px);
      }
    }
    ${mediaSize.forTabletOnly} {
      width: 324px;
      & .imgWrap {
        right: calc(0% - 30px);
      }
    }
    ${mediaSize.forMobileOnly} {
      width: calc(100vw - 32px);
      margin: 0;
      & .imgWrap {
        right: calc(0% - 45px);
      }
    }
  }
`;
export const cardTitle = css`
  margin-bottom: 4px;
  text-align: left;
  color: #333333;
  &[data-size='lg'] {
    max-width: 152px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }
  &[data-size='sm'] {
    max-width: 122px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;
export const cardDesc = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #666666;
  &[data-size='lg'] {
    max-width: 152px;
    font-size: 14px;
    line-height: 18px;
  }
  &[data-size='sm'] {
    max-width: 122px;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const btnStyle = css`
  &[data-type='outlined'] {
    padding: 8px 18px;
    font-weight: 500;
    font-size: 14px;
    font-family: inherit;
    line-height: 20px;
    color: #333333;
    background: #f2f3f5;
    border: 2px solid #ced0d6;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }
`;
