export const parseUserData = (userData = '') => {
  // лишние одиночные кавычки выкидываем, потому что они остаются внутри строки после парсинга кук
  const userDataUncoded = decodeURIComponent(userData.split("'").join('').split('+').join(''));
  try {
    const resObj = JSON.parse(userDataUncoded);
    return resObj;
  } catch (e) {
    return {};
  }
};
