import { css } from 'linaria';

export const icon = css`
  width: 24px;
  height: 24px;
`;

export const buttonStyle = css`
  position: absolute;
  top: 50%;

  z-index: 2;
  margin-right: 0;
  background: var(--control-invert);
  box-shadow: var(--box-shadow-m);

  --translate-distance: 0 !important;

  &:hover {
    background: var(--control-invert);
  }

  &.left {
    left: 0;
    transform: translateY(-50%) translateX(-50%);
    span {
      left: -1px;
    }
  }

  &.right {
    right: 0;
    transform: translateY(-50%) translateX(50%);
    span {
      right: -1px;
    }
  }
`;
