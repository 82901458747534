import React, { FC } from 'react';
import { Container, item } from './styles';

const COUNT = 7;

export const BottomPanel: FC = () => (
  <Container>
    {Array.from({ length: COUNT }).map((_, index) => (
      <svg
        width="109"
        height="16"
        viewBox="0 0 109 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={item}
        key={index}
      >
        <path
          d="M7.63751 9.87363C6.96713 9.54073 6.24862 9.36978 5.52314 9.37053C4.79766 9.36978 4.07915 9.54073 3.40878 9.87363C2.7384 10.2065 2.12929 10.6948 1.61629 11.3106C1.1033 11.9263 0.696505 12.6575 0.419165 13.4621C0.143046 14.2633 0.000629834 15.1216 2.08438e-06 15.9885L2.21133e-05 0H108.049V16H101.437C101.395 14.2748 100.794 12.6372 99.7625 11.4348C98.7308 10.2324 97.3495 9.55983 95.9115 9.55983C94.4736 9.55983 93.0922 10.2324 92.0606 11.4348C91.029 12.6372 90.4283 14.2748 90.386 16H83.3579C83.3157 14.2748 82.7149 12.6372 81.6833 11.4348C80.6517 10.2324 79.2703 9.55983 77.8324 9.55983C76.3944 9.55983 75.0131 10.2324 73.9815 11.4348C72.9499 12.6372 72.3492 14.2748 72.3069 16H65.2788C65.2788 14.2418 64.6969 12.5555 63.6611 11.3123C62.6254 10.069 61.2205 9.37053 59.7557 9.37053C58.2909 9.37053 56.886 10.069 55.8502 11.3123C54.8145 12.5555 54.2326 14.2418 54.2326 16H47.2045C47.1622 14.2748 46.5615 12.6372 45.5299 11.4348C44.4983 10.2324 43.1169 9.55983 41.679 9.55983C40.241 9.55983 38.8596 10.2324 37.828 11.4348C36.7964 12.6372 36.1957 14.2748 36.1535 16H29.1254C29.0831 14.2748 28.4824 12.6372 27.4508 11.4348C26.4192 10.2324 25.0378 9.55983 23.5998 9.55983C22.1619 9.55983 20.7805 10.2324 19.7489 11.4348C18.7173 12.6372 18.1166 14.2748 18.0744 16H11.0463C11.0469 15.1292 10.9045 14.2668 10.6271 13.4621C10.3498 12.6575 9.94298 11.9263 9.42999 11.3106C8.917 10.6948 8.30788 10.2065 7.63751 9.87363Z"
          fill="white"
        />
      </svg>
    ))}
  </Container>
);
