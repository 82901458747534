import React, { FC, useRef } from 'react';
import { patchUrlByLocale } from 'utils';
import { RegularButton } from 'fronton-react';
import { analyticsUtils } from 'utils/analytics-utils';
import { useIntersectionObserverCallback } from 'hooks/use-intersection-observer-callback';
import { GridImageContainer } from '../grid-image-container';
import { InfoContainer } from '../../container-components/info-container';
import { GGRProps } from './ggr.types';
import { container, turnkeySolutionCard, button } from './ggr.styles';

export const GGR: FC<GGRProps> = (turnkeyProps) => {
  const {
    title,
    description,
    buttonLink,
    buttonTitle,
    image1,
    image2,
    image3,
    gaName,
    gaPosition,
    gaCreative,
  } = turnkeyProps;
  const ggrRef = useRef<HTMLDivElement | null>(null);
  const images = { image1, image2, image3 };

  const handleClick = () => {
    analyticsUtils.sendEvent('GA:promoClick', {
      elementType: 'banner',
      elementBlock: 'ggr', // блок, в котором расположен элемент
      promotions: [
        {
          creative: gaCreative, // добавляется при загрузке баннера через админку
          name: gaName, // название блока с баннером (добавляется при загрузке баннера через админку)
          position: gaPosition, //  позиция баннера (добавляется при загрузке баннера через админку)
        },
      ],
    });
  };

  useIntersectionObserverCallback(ggrRef, {
    enter: () => {
      analyticsUtils.sendEvent('GA:promoView', {
        elementType: 'banner',
        elementBlock: 'ggr', // блок, в котором расположен элемент
        promotions: [
          {
            creative: gaCreative, // добавляется при загрузке баннера через админку
            name: gaName, // название блока с баннером (добавляется при загрузке баннера через админку)
            position: gaPosition, //  позиция баннера (добавляется при загрузке баннера через админку)
          },
        ],
      });
    },
    extraUnobserve: 'enter',
  });

  const link = patchUrlByLocale(buttonLink);

  return (
    <div ref={ggrRef} className={container}>
      <div className={turnkeySolutionCard}>
        <InfoContainer cardHeader={title} cardDescription={description}>
          <RegularButton
            className={button}
            variant="primary"
            as="a"
            href={link}
            onClick={handleClick}
            size="m"
          >
            {buttonTitle}
          </RegularButton>
        </InfoContainer>
        <GridImageContainer {...images} link={link} />
      </div>
    </div>
  );
};
