import { css } from 'linaria';
import { mediaSize } from '../../../styles/media-size';

export const container = css`
  height: 100%;
  padding: 0 var(--layout-gap);
  margin: 0 auto;
`;

export const turnkeySolutionCard = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${mediaSize.forTabletOnly} {
    flex-direction: column-reverse;
    align-items: baseline;
    max-width: 660px;
  }
  ${mediaSize.forMobileOnly} {
    flex-direction: column-reverse;
    align-items: baseline;
  }
`;

export const button = css`
  ${mediaSize.forMobileOnly} {
    width: 100%;
  }
`;
