import { styled } from 'linaria/react';
import { mediaSize } from '../../../styles/media-size';

export const ListItem = styled.li`
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 0;
`;

export const RowLink = styled.a`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  color: #707070;
  &:hover {
    color: #333333;
  }
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  ${mediaSize.forMobileOnly} {
    font-size: 12px;
    line-height: 16px;
  }
`;
