import React from 'react';
import { patchUrlByLocale } from 'utils';
import { carouselWrapperStyles } from 'styles/common-classes';
import { OfferCard } from '../offer-card';
import { Carousel } from '../../carousel-components/carousel';
import { CarouselItem } from '../../carousel-components/carousel-item';
import { wrapperStylesAdaptive, bannerItem } from './offer-carousel.styles';
import { Props } from './offer-carousel.types';

export const OfferCarousel = ({ data }: Props) => {
  return (
    <div className={`${carouselWrapperStyles} ${wrapperStylesAdaptive}`}>
      <Carousel
        autoplay={data.autoplay}
        isBannerCarousel
        controlSettings={{
          variant: 'secondary',
          size: 'l',
          rounded: true,
          iconOnly: true,
          iconSize: 16,
        }}
      >
        {data.offers
          .filter(({ desktopRef, tabletRef, mobileRef }) => desktopRef && tabletRef && mobileRef)
          .map((item, idx) => {
            return (
              <CarouselItem
                key={item.title}
                href={patchUrlByLocale(item.pathLink)}
                className={bannerItem}
                variant="secondary"
                data={{
                  ...item,
                  idx,
                  elementBlock: 'mainBannerBottom',
                }}
              >
                <OfferCard {...item} />
              </CarouselItem>
            );
          })}
      </Carousel>
    </div>
  );
};
