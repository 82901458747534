import React, { FC } from 'react';
import { useOptimizedImages } from 'hooks/use-optimized-images';
import { GridContainerProps } from './grid-image-container.types';
import { grid } from './grid-image-container.styles';

const ggrSizeLg = {
  rest: {
    h: '358',
  },
};
const ggrSizeSm = {
  rest: {
    h: '196',
  },
};

export const GridImageContainer: FC<GridContainerProps> = ({ image1, image2, image3, link }) => {
  const ggrPhoto1 = useOptimizedImages(image1, ggrSizeSm);
  const ggrPhoto2 = useOptimizedImages(image2, ggrSizeLg);
  const ggrPhoto3 = useOptimizedImages(image3, ggrSizeSm);
  const ggrPhotos = [ggrPhoto1, ggrPhoto3, ggrPhoto2];
  return (
    <div className={grid}>
      {[image1, image3, image2].map((img, i) => (
        <picture key={img}>
          <a href={link}>
            <source srcSet={`${ggrPhotos[i].rest?.xx} 2x, ${ggrPhotos[i].rest?.x} 1x`} />
            <img
              alt={`Изображение${i}`}
              src={ggrPhotos[i].rest?.x ?? undefined}
              srcSet={`${ggrPhotos[i].rest?.xx} 2x, ${ggrPhotos[i].rest?.x} 1x`}
            />
          </a>
        </picture>
      ))}
    </div>
  );
};
