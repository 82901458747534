import React, { FC } from 'react';
import { cx } from 'linaria';
import { image } from './styles';

export const DATA_QA_SHOCK_LOGO = 'shock-price-logo';

type Props = {
  className?: string;
};

export const ShockLogo: FC<Props> = ({ className }) => {
  const src =
    'https://cdn.lemanapro.ru/lmru/image/upload/v1712164191/fudzi/navigation-microfronts/shock-price.svg/shock-price.svg';
  return <img src={src} alt="" className={cx(image, className)} data-qa={DATA_QA_SHOCK_LOGO} />;
};
