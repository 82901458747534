import { IntersectionObserverOptions } from './types';

/**
 * Stringify options for use as a key.
 * Excludes options.root so that the resulting key is stable
 *
 * @param {Object} options
 * @return {String}
 */
export const stringifyOptions = (options: IntersectionObserverOptions) => {
  const { root } = options;

  const replacer = (key: string, value: string) => {
    if (
      key === 'root' &&
      root &&
      Object.prototype.hasOwnProperty.call(root, 'classList') &&
      Object.prototype.hasOwnProperty.call(root, 'id')
    ) {
      const classList: string[] = Array.prototype.slice.call(root.classList);
      const classToken = classList.reduce((acc, current) => acc + current, '');
      const ids = root.id;
      return `${ids}-${classToken}`;
    }
    return value;
  };

  return JSON.stringify(options, replacer);
};
