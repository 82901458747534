import { css } from 'linaria';

export const layout = css`
  padding-right: var(--layout-gap);
  padding-left: var(--layout-gap);
  margin-top: 0 !important;

  @media (--only-tablet) {
    width: calc(var(--layout-width) + 2 * var(--layout-gap));
  }
`;

export const onlyInStoreText = css`
  font-size: 12px !important;
  font-style: normal !important;
  line-height: 24px;
`;

export const onlyInStore = css`
  margin-bottom: var(--space-800);
  background: #ffed00;

  @media (--only-mobile) {
    margin-bottom: var(--space-600);
  }
`;
