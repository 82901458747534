import React, { memo, StrictMode, Suspense } from 'react';
import { InBrowser, AtServer, CssBaseline, ThemeProvider } from 'fronton-react';
import { appStyle } from './app.styles';
import './styles/base.css';
import { Routes } from './pages';

export const App = memo(() => {
  return (
    <main className={appStyle}>
      <ThemeProvider>
        <StrictMode>
          <InBrowser>
            <Suspense fallback={null}>
              <Routes />
            </Suspense>
          </InBrowser>
          <AtServer>
            <Routes />
          </AtServer>
          <CssBaseline />
        </StrictMode>
      </ThemeProvider>
    </main>
  );
});
