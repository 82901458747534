import React, { FC } from 'react';
import { useOptimizedImages } from 'hooks/use-optimized-images';
import { useBreakpoints } from 'hooks';
import { container } from './offer-card.styles';
import { bannerImage, bannerPicture, OfferCardProps } from './offer-card.types';

export const DATA_QA_OFFER_CARD_CONTAINER = 'offer-card-container';
export const DATA_QA_OFFER_CARD_PICTURE = 'offer-card-banner-picture';
export const DATA_QA_OFFER_CARD_IMAGE = 'offer-card-banner-image';

const rests = {
  desktop: { w: '1180', h: '264' },
  tablet: { w: '660', h: '264' },
  mobile: { w: '528', h: '660' },
};

export const OfferCard: FC<OfferCardProps> = (offerData) => {
  const { mobile = false, tablet = false } = useBreakpoints() || {};

  const { desktopRef, tabletRef, mobileRef, title } = offerData;
  const desktopOptimizedImage = useOptimizedImages(desktopRef, { rest: rests.desktop }, true);
  const tabletOptimizedImage = useOptimizedImages(tabletRef, { rest: rests.tablet }, true);
  const mobileOptimizedImage = useOptimizedImages(mobileRef, { rest: rests.mobile }, true);

  const { width, height } = mobile
    ? { width: rests.mobile.w, height: rests.mobile.h }
    : tablet
      ? { width: rests.tablet.w, height: rests.tablet.h }
      : { width: rests.desktop.w, height: rests.desktop.h };

  return (
    <div className={container} data-qa={DATA_QA_OFFER_CARD_CONTAINER}>
      <picture className={bannerPicture} data-qa={DATA_QA_OFFER_CARD_PICTURE}>
        <source
          media="(min-width: 992px)"
          srcSet={`${desktopOptimizedImage.rest?.xx} 2x, ${desktopOptimizedImage.rest?.x} 1x`}
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${tabletOptimizedImage.rest?.xx} 2x, ${tabletOptimizedImage.rest?.x} 1x`}
        />
        <img
          className={bannerImage}
          srcSet={`${mobileOptimizedImage.rest?.xx} 2x, ${mobileOptimizedImage.rest?.x} 1x`}
          alt={title}
          data-qa={DATA_QA_OFFER_CARD_IMAGE}
          width={width}
          height={height}
        />
      </picture>
    </div>
  );
};
